import { Chart } from "chart.js";
import { RainbowColours } from "@/components/RainbowColours";

const DrawRainbowCurves = (chartCtx, title, comparisonData, playerData, displayTitle, yLimit, padding, yLabel, additionalData, toolTips) => {
    //order describes how the lines are stacked. Lower values come on top.
    const createDataset = (values, colour, isPlayer, fill, order, label, toolTips) => {
        return {
            //TODO: Get rid of approximation when metric conversion has been added to all screens using this component
            data: values,
            fill: fill,
            backgroundColor: colour,
            borderColor: colour,
            tension: 0,
            radius: isPlayer ? 3 : 0,
            borderWidth: isPlayer ? 3 : 0,
            order: order,
            label: label,
            toolTips: toolTips
        };
    };
    const colours = {
        lavender:'#b19cd9', tangerine:'#F98B60', white:'#ffff', steelBlue:'#3E6B8E', skyBlue:'#6BB2D6',
        forestGreen:'#5B9E5C', fuchsia:'#E861A2', purple:'#7851A9', coral:'#cc3B', pink:'#CC5B8B',
        babyPink:'#FAc2C2', mauve:'#8F5E7B', turquoise:'#63D9B2', cinnamon:'#C56E1A', teal:'#3A8F8E', brown:'#6F4E37'
    };

    let datasets;

    /*
    If we have additional data (e.g. extra players on Opposition Analysis, we need to check all values for the max to
    ensure they are all visible on the chart
     */

    let additionalDataMax = 0;
    const createAdditionalDatasets = () => {
        if (additionalData !== null) {
            let i = 0;
            for (const data of additionalData) {
                const colour = colours[Object.keys(colours)[i]];
                datasets.push(createDataset(data.values, colour, true, false, 1, data.name, data.toolTips));
                i = (i + 1) % Object.keys(colours).length;
                additionalDataMax = (Math.max(additionalDataMax, (Math.max(...data.values))));
            }
        }
    };

    if (comparisonData === null) {
        datasets = [
            createDataset(playerData, RainbowColours.blue, true, false, 0, null, toolTips)
        ];
        createAdditionalDatasets();
    } else {
        /*
        Checks if any 15% values are lower than the min or 85% values are higher than the max and replaces them, rare
        occurrence but causes a messy overlap in the graph
        */
        comparisonData.epochPercentile15Values = comparisonData.epochPercentile15Values.map((value, i) => Math.max(value, comparisonData.epochMinValues[i]));
        comparisonData.epochPercentile85Values = comparisonData.epochPercentile85Values.map((value, i) => Math.min(value, comparisonData.epochMaxValues[i]));

        datasets = [
            createDataset(playerData, RainbowColours.blue, true, false, 0, null, toolTips),
            createDataset(comparisonData.epochMaxValues, RainbowColours.green, false, false, 2, null),
            createDataset(comparisonData.epochPercentile85Values, RainbowColours.green, false, '-1', 2, null),
            createDataset(comparisonData.epochPercentile65Values, RainbowColours.lime, false, '-1', 2, null),
            createDataset(comparisonData.epochPercentile35Values, RainbowColours.yellow, false, '-1', 2, null),
            createDataset(comparisonData.epochPercentile15Values, RainbowColours.orange, false, '-1', 2, null),
            createDataset(comparisonData.epochMinValues, RainbowColours.red, false, '-1', 2, null)
        ];
        createAdditionalDatasets();
    }

    if (yLimit === null) {
        yLimit = Math.max(...playerData);
        if (additionalDataMax > 0) {
            yLimit = Math.max(yLimit, additionalDataMax);
        }

        if (comparisonData !== null) {
            yLimit = Math.max(yLimit, ...comparisonData.epochMaxValues);
        }
    }

    let roundMax = yLimit > 0 ? Math.ceil(yLimit / padding) * padding : yLimit + padding;

    return new Chart(chartCtx, {
        type: 'line',
        data: {
            title: 'title',
            labels: ['30s', '60s', '120s', '180s', '300s', '600s'],
            datasets: datasets
        },
        options: {
            title: {
                display: displayTitle,
                text: title,
                maintainAspectRatio: false,
            },
            tooltips: {
                yAlign: 'top',
                enabled: true,
                mode: 'single',
                callbacks: {
                    title: (tooltipItem) => {
                        const additionalDataLabel = datasets[tooltipItem[0].datasetIndex].label;
                        return additionalDataLabel ? additionalDataLabel : tooltipItem[0].label;
                    },
                    label: (tooltipItem) => {
                        if (datasets[tooltipItem.datasetIndex].toolTips) {
                            return datasets[tooltipItem.datasetIndex].toolTips[tooltipItem.index];
                        }
                    }
                }
            },
            hover: {
                mode: null
            },
            legend: {
                display: false
            },
            scales: {
                yAxes: [{
                    gridLines: {
                        display: false,
                    },
                    scaleLabel: {
                        display: true,
                        labelString: yLabel
                    },
                    ticks: {
                        max: roundMax,
                        stepSize: padding
                    }
                }],
                xAxes: [{
                    gridLines: {
                        display: false,
                    },
                    scaleLabel: {
                        display: true,
                        labelString: 'Epoch (s)'
                    },
                }]
            }
        }
    });
};

export default DrawRainbowCurves