<template>
    <div id="content-wrap" :style="{ opacity: !$root.isLoadingData ? 1 : 0 }" style="margin-left:90px">
        <PageHeader>
            <PageHeaderTitle>
                <h1>
                    {{pageTitle}}
                </h1>
            </PageHeaderTitle>
        </PageHeader>

        <div class="section-container" v-if="!hasSaudiDocs">
            <SectionTitle title='Match Reporting' />
            <div class="white-paper-container">
                <a href=" ../doc/Sportlight_Match_Report.pdf" target="_blank"> Sportlight Match Report </a>
                <a href=" ../doc/Change_Of_Direction_Metrics.pdf" target="_blank"> Change Of Direction Metrics </a>
            </div>
        </div>

        <div class="section-container" v-if="!hasSaudiDocs">
            <SectionTitle title='Wellness' />
            <div class="white-paper-container">
                <a href=" ../doc/Fatigue_Flag.pdf" target="_blank"> Fatigue Flag </a>
                <a href="../doc/Game_Demand.pdf" target="_blank"> Game Demand </a>
                <a href="../doc/Hamstring_Risk.pdf" target="_blank"> Hamstring Risk </a>
                <a href="../doc/Local_Intensity.pdf" target="_blank"> Local Intensity </a>
            </div>
        </div>

        <div class="section-container" v-if="hasSaudiDocs">
            <SectionTitle title='SPL Documentation & Definitions' />
            <div class="white-paper-container">
                <a href=" ../doc/SPL_Portal_KPI_Glossary.pdf" target="_blank"> SPL Portal KPI Glossary </a>
                <a href="../doc/SPL_Portal_KPI_Glossary(Arabic).pdf" target="_blank"> SPL Portal KPI Glossary (Arabic) </a>
                <a href="../doc/SPL_Portal_KPI_Glossary(Spanish).pdf" target="_blank"> SPL Portal KPI Glossary (Spanish) </a>
            </div>
            <br>
            <div class="col-12">
                <h4 style="font-weight: 700 !important;">Definitions</h4>
                <ul class="definitions">
                    <li>
                        <span>Work Rate:</span> Developed in collaboration with the Saudi Pro League, Work Rate is a KPI that overviews a
                        player’s workload and is designed to be an approximate marker of heart rate. An overall
                        value and the time spent above various work rate thresholds are presented for players.
                    </li>
                    <li>
                        <span>Fatigue Flag:</span> This KPI is based on Sportlight's AI model that individually monitors each player’s
                        condition to detect early signs of fatigue following a match. A colour flag is used to highlight
                        the level of risk. For more information on this KPI, you can read this white paper 
                        <a href=" ../doc/Fatigue_Flag.pdf" target="_blank">[link]</a>
                    </li>
                    <li>
                        <span>Hamstring Risk:</span> This KPI is based on Sportlight's AI model that assigns high risk scores to players
                        following a match with similar profiles to those that have previously been associated with
                        hamstring injuries. A colour flag is also used to highlight the level of risk. For more information 
                        on this KPI, you can read this white paper <a href="../doc/Hamstring_Risk.pdf" target="_blank">[link]</a>
                    </li>
                    <li>
                        <span>Speed, Acceleration and Deceleration Distances:</span> Sportlight provides a list of KPIs for the distance 
                        covered by players above various speed, acceleration and deceleration thresholds.
                    </li>
                    <li>
                        <span>Comparison Features:</span> Unique to Sportlight, a set of comparison filters and colour-coded percentile rankings can be
                        used to compare match data between players, teams and leagues after just one match.
                    </li>
                </ul>
            </div>
            <br>
            <div class="col-12">
                <h4 style="font-weight: 700 !important;">Updates</h4>
                <ul class="definitions">
                    <a href=" ../doc/SPL_Position_Filter_Feb_2025.pdf" target="_blank">SPL Postion Filter (Feb 2025)</a>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import { UserData } from '@/components/UserData';
import PageHeader from '@/components/PageHeader.vue';
import PageHeaderTitle from '@/components/PageHeaderTitle.vue';
import SectionTitle from '@/components/SectionTitle.vue';

export default {
    components: {
        PageHeader,
        PageHeaderTitle,
        SectionTitle
    },
    data() {
        return {
            hasSaudiDocs: UserData.hasSaudiDocumentationAndSupport()
        };
    },
    computed: {
        pageTitle() {
            return this.hasSaudiDocs ? 'Documentation' : 'White Papers';
        }
    },
    async mounted() {
        console.log("Whitepaper.vue mounted");
        this.$root.newPageView("Whitepaper Page", UserData.userName());
    }
};
</script>

<style>
.white-paper-container {
    display: flex;
    color: var(--sportlight-teal);
    margin: auto;
    width: 100%;
}

.white-paper-container a {
    padding: 0 1em;
    color: var(--sportlight-teal);
}

.definitions li {
    margin-bottom: 10px;
}

.definitions a {
    color: var(--sportlight-teal);
}

.definitions span {
    font-weight: 700 !important;
}
</style>