<template>
    <div class="section-container">
        <SectionTitle title='Physical Performance Ranking'>
            <MoreInfo :id="'sdn-player-performance-standards-more-info'" title='Physical Performance Ranking'>
                <p>
                    The player's average full-match physical 
                    outputs relative to the SDN database.
                </p>
            </MoreInfo>
        </SectionTitle>
        <MissingDataText :message="'Insufficient data for player'" v-if="!hasData" />
        <div v-else>
            <RainbowLegend :showBlueKey=false keyText='Network' class="mt-4" />
            <div class="scroll-container">
                <div style="margin-top: 2vh; min-width: max-content;">
                    <div class="row mx-auto d-flex justify-content-center align-items-center">
                        <span class="col-2 fixed-name-column column-heading text-left"></span>
                        <span class="col-10 d-flex">
                            <h6 class="column-heading">Total Distance (<MeasurementUnit :type="LongDistance"/>)</h6> 
                            <h6 class="column-heading">High Speed Running (<MeasurementUnit :type="ShortDistance"/>)</h6> 
                            <h6 class="column-heading">Sprint Distance (<MeasurementUnit :type="ShortDistance"/>)</h6> 
                            <h6 class="column-heading">Turns (N)</h6> 
                            <h6 class="column-heading">Accelerations &gt; <MeasurementValue :type="Acceleration" :value=3 :format="formatCoarse"/>
                                <MeasurementUnit :type="Acceleration" /> (N)</h6> 
                            <h6 class="column-heading">Decelerations &gt; <MeasurementValue :type="Acceleration" :value=3 :format="formatCoarse"/>
                                <MeasurementUnit :type="Acceleration" /> (N)</h6> 
                        </span>
                    </div>
                    <hr>
                    <div v-if="hasData">
                        <div class="row mx-auto d-flex justify-content-center align-items-center">
                            <span class="col-2 d-flex align-items-center rainbow-row-container fixed-name-column">{{ player.name }}</span>
                            <div class="col-10 d-flex">
                                <div class="rainbow-row-container  d-flex align-items-center">
                                    <RainbowBarChart class="rainbow-bar-charts" v-for="metric in metrics" :key="metric"
                                        :id="metric + player.playerId" :data="data[metric]" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import RainbowBarChart from "@/components/RainbowBarChart.vue";
import MissingDataText from "@/components/MissingDataText.vue";
import RainbowLegend from "@/components/RainbowLegend.vue";
import SectionTitle from "@/components/SectionTitle.vue";
import MoreInfo from "@/components/MoreInfo.vue";
import MeasurementUnit from "@/components/MeasurementUnit.vue"; 
import MeasurementValue from "@/components/MeasurementValue.vue"; 
import { MeasurementTypes, MeasurementUnit as MU } from "@/utils/MeasurementSystem";

export default {
    components: {
        RainbowBarChart,
        MissingDataText,
        RainbowLegend,
        SectionTitle,
        MoreInfo,
        MeasurementUnit, 
        MeasurementValue,    
    },
    props: {
        player: Object,
        data: Object,
        hasData: Boolean
    },
    data() {
        return {
            Acceleration: MeasurementTypes.Acceleration, 
            ShortDistance: MeasurementTypes.ShortDistance, 
            LongDistance: MeasurementTypes.LongDistance, 
            formatFine: MU.prototype.formatFine,
            formatCoarse: MU.prototype.formatCoarse,
            metrics: ['distanceData', 'hsdData', 'sprintDistanceData', 'turnsData', 'accelerationsData', 'decelerationsData'],
        };
    },
};
</script>

<style scoped>
.rainbow-bar-charts {
    padding: 0 0.1em;
}
.column-heading {
    height: 4vh;
    width: 12vw;
    margin: auto 0;
    text-align: center;
}

.fixed-name-column {
    position: sticky;
    left: 0;
    z-index: 1;
    background-color: var(--bg-primary);
    font-size: 12px;
    font-weight: 400;
}
hr {
    border: none;
    border-top: 1px solid #5a5e6280;
    margin: 0.7em 0;
}

.scroll-container {
    overflow-x: auto;
    white-space: nowrap;
    width: 100%;
}

@media (max-width: 992px) {
    .rainbow-row-container, .column-heading {
        min-width: 25vw;
    }
}
</style>