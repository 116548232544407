<template>
    <div id="content-wrap" style="margin-left:90px" :style="{ opacity: !$root.isLoadingData ? 1 : 0 }">
        <WellnessHeader />
        <PageLayout>
            <MissingDataText v-if="!hasWellnessData" message="Wellness data is unavailable in demonstration mode" />
            <div class="row m-0 p-0" style="width:92vw" v-else>
                <div class="col-11 col-lg-6" v-for="(player, key) in playerDetails" :key="key">
                    <div class="w-100 mt-2 mx-auto text-center section-container">
                        <h5 style="color: var(--white)" class="mx-auto">{{ player.player.name }}</h5>
                        <div class="row heading grey-line">
                            <div class="title" :class="getColumnClass(key, 'col-3', 'col-0')"></div>
                            <div class="title col-1">Fatigue Flag</div>
                            <div class="title col-1">Game Demand</div>
                            <div class="title col-1">Hamstring Risk</div>
                            <div class="title" :class="getColumnClass(key, 'col-6', 'col-8')">Local Intensity (<MeasurementUnit :type="Speed" />)
                            </div>
                        </div>
                        <div class="row mx-auto" v-for="(session, sessionId) in sessionDetails" :key="sessionId">
                            <div class="kpi-col" :class="getColumnClass(key, 'col-3', 'col-0')"
                                v-if="key % 2 === 0 || key === 0 || isSmallerScreen" id="session-description">
                                {{ session.date }} <br />
                                {{ 'vs ' + session.title.substr(0, session.title.indexOf('(')) }}
                            </div>
                            <div class="kpi-col" :class="getColumnClass(key, 'col-3', 'col-0')" v-else></div>
                            <template v-if="player.valuesBySessionId[sessionId]">
                                <div class="col-1 kpi-col circular-plot-width">
                                    <div
                                        :class="['fatigue-flag', player.valuesBySessionId[sessionId].fatigueFlagColour]">
                                    </div>
                                </div>
                                <div class="col-1 kpi-col circular-plot-width">
                                    <RadialBar :colour="player.valuesBySessionId[sessionId].gameDemandDetails.colour"
                                        :percentage="player.valuesBySessionId[sessionId].gameDemandDetails.value" />
                                </div>
                                <div class="col-1 kpi-col circular-plot-width">
                                    <RadialBar :colour="player.valuesBySessionId[sessionId].hamstringRiskColour"
                                        :percentage="player.valuesBySessionId[sessionId].formattedHamstringRiskScore"
                                        :hasFixedRadial="true" />
                                </div>
                                <div class="kpi-col" :class="getColumnClass(key, 'col-6', 'col-9')">
                                    <LocalIntensityPlot :id="player.player.playerId + '-' + sessionId"
                                        :data="player.valuesBySessionId[sessionId].localIntensity"
                                        :maxSessionTime="maxSessionTime" />
                                </div>
                            </template>
                            <template v-else>
                                <div class="kpi-col" :class="getColumnClass(key, 'col-9', 'col-12')"
                                    id="missing-row-text">
                                    Player did not feature in this game
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </PageLayout>
    </div>
</template>

<script>
import { UserData } from "@/components/UserData";
import Colours from "@/utils/Colours";
import PageLayout from '@/components/PageLayout.vue';
import { errorHandler } from "@/components/ErrorHandler";
import LocalIntensityPlot from "./LocalIntensityPlot.vue";
import RadialBar from "@/components/RadialBar.vue";
import UpdateSetting from "@/utils/UpdateSetting";
import MeasurementUnit from "@/components/MeasurementUnit.vue";
import { MeasurementTypes } from "@/utils/MeasurementSystem";
import { getFlagColour } from "@/utils/FatigueFlagsValues";
import WellnessHeader from "./WellnessHeader.vue";
import MissingDataText from '@/components/MissingDataText.vue';
import BREAKPOINT_LG from "@/utils/Breakpoints";

export default {
    components: {
        PageLayout,
        LocalIntensityPlot,
        RadialBar,
        MeasurementUnit,
        WellnessHeader,
        MissingDataText
    },
    data() {
        return {
            customerId: UserData.customerId(),
            data: null,
            sessionDetails: null,
            playerDetails: null,
            Speed: MeasurementTypes.Speed,
            hasWellnessData: false,
            maxSessionTime: 0,
            isSmallerScreen: window.innerWidth <= BREAKPOINT_LG,
        };
    },
    methods: {
        navigateTo(newPage) {
            this.$router.push(newPage).catch(() => { });
        },
        async getChart() {
            const response = await this.$root.webApiGet(`/wellnessreport?customerId=${this.customerId}`);
            if (response.status === 200) {
                const { wellnessPlayerData, wellnessSessionsDetails } = response.data;
                this.hasWellnessData = wellnessPlayerData && wellnessPlayerData.length > 0;
                if (this.hasWellnessData) {
                    const getGameDemandColour = value => {
                        if (value >= 90) {
                            return Colours.BRIGHT_GREEN;
                        } else if (value <= 10) {
                            return Colours.RED;
                        } else {
                            return Colours.SPORTLIGHT_TEAL;
                        }
                    };
                    
                    const getHamstringRiskColour = hasHamstringRisk => hasHamstringRisk === null ? null :
                                                (hasHamstringRisk ? Colours.RED : Colours.BRIGHT_GREEN);

                    const formatHamstringRiskScore = hamstringRiskScore => hamstringRiskScore === null ? null : `${hamstringRiskScore}x`;

                    wellnessPlayerData.forEach(player => {
                        const sessionData = player.valuesBySessionId;
                        Object.keys(sessionData).forEach(key => {
                            const session = sessionData[key];
                            session.fatigueFlagColour = getFlagColour(session.fatigueFlagValue);
                            session.gameDemandDetails = {
                                value: session.gameDemandValue === null ? session.gameDemandValue : `${session.gameDemandValue}`,
                                colour: getGameDemandColour(session.gameDemandValue)
                            };
                            session.hamstringRiskColour = getHamstringRiskColour(session.hamstringRisk);
                            session.formattedHamstringRiskScore = formatHamstringRiskScore(session.hamstringRiskScore);
                            const localIntensityKeys = Object.keys(session.localIntensity);
                            if (localIntensityKeys.length > 0) {
                                this.maxSessionTime = Math.max(this.maxSessionTime, Math.max(...localIntensityKeys));
                            }
                        });
                    });

                    this.sessionDetails = wellnessSessionsDetails;
                    this.playerDetails = wellnessPlayerData;
                }
            } else {
                errorHandler.error(response, this);
            }
        },
        getColumnClass(key, colA, colB) {
            return key % 2 === 0 || this.isSmallerScreen ? colA : colB;
        },
        updateViewport() {
            this.isSmallerScreen = window.innerWidth <= BREAKPOINT_LG;
        }
    },
    async mounted() {
        await this.$root.executeTaskWithProgressBar(async () => {
            await this.getChart();
            window.addEventListener("resize", this.updateViewport);
            console.log('WellnessReport.vue mounted');
            UpdateSetting();
            this.$root.newPageView("Wellness Report Page", UserData.userName());
        });
    }
};
</script>

<style scoped>
.heading, #session-description, #missing-row-text { 
    color: var(--bg-secondary-dark);
    font-size:smaller
}
#session-description {
    text-align: left; 
    white-space: nowrap
}
.title {
    padding: 0; 
    min-height: 6vh;
}
.kpi-col {
    min-height: 6vh;
}
.hamstring-risk {
    width: 2em;
    height: 2em;
    border: 5px solid var(--hamstring-risk-colour);
    border-radius: 50%;
    position: relative;
    margin: auto;
}
.fatigue-flag {
    width: 2em;
    height: 2em;
    position: relative;
    margin: auto;
    border-radius: 50%;
}
.fatigue-flag .red-flag {
    background: var(--red);
}
.fatigue-flag .green-flag {
    background: var(--green);
}
.fatigue-flag .amber-flag {
    background: var(--amber-flag);
}
.circular-plot-width {
    width: 4vw;
}
.grey-line {
    border: 2px; 
    border-bottom: 1px solid #5a5e6280;
    margin: 0.7em 0;
}
</style>