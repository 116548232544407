<template>
    <div :style="{ opacity: !$root.isLoadingData ? 1 : 0 }" id="content-wrap" style="padding-left: 90px">
        <SessionHeader />
        <PageLayout>
            <div>
                <Button id="reset-drill" :type="'secondary'" title="Back to drill management" :onClick="resetDrill"
                    name="reset-drill" class="px-0 ml-auto" v-if="drillIndex != null" />
                <DropdownSelector class="small-dropdown mt-2" :items="drills" :getItemName="getDrillLabel"
                    :index="drillIndex" @item-selected="handleDrillSelection" v-if="drills.length > 0" />

                <div class="row d-flex justify-content-center align-items-center text-center py-2">
                    <div class="col-2"></div>
                    <div class="col-9 w-75 pl-4">
                        <vue-slider class="pl-4" :value="[timeStringToSeconds(startTime), timeStringToSeconds(endTime)]"
                            @change="updateSliderRange" :min="timeStringToSeconds(sessionStartTime)"
                            :max="timeStringToSeconds(sessionEndTime)" :step="1" :tooltip="'always'"
                            :dot-style="{ backgroundColor: sliderColour }"
                            :process-style="{ backgroundColor: sliderColour }"
                            :tooltip-style="{ backgroundColor: '#2F3234', color: '#fff', border: '2px solid ' + sliderColour }"
                            :tooltip-formatter="formatTime" :marks="sliderMarks">
                        </vue-slider>
                    </div>
                    <div class="col-1">
                        <Button id="add-drill" :type="'primary'" title="Add Drill" :onClick="showDrillModal"
                            name="add-drill" v-b-modal="'drill-edit-modal'" v-if="drillIndex === null" />
                        <div class="col mx-auto px-2" v-else>
                            <Button id="edit-drill" :type="'primary'" title="Edit Drill" :onClick="editDrill"
                                name="edit-drill" v-b-modal="'drill-edit-modal'" class="mb-2" />
                            <Button id="delete-drill" :type="'danger'" title="Delete Drill" :onClick="deleteDrill"
                                name="delete-drill" />
                        </div>
                    </div>
                </div>

                <hr class="my-4">
                <div class="row align-items-center py-2" v-for="player in players" :key="player.playerId">
                    <div class="col-2 text-center">
                        {{ player.playerName }}
                    </div>
                    <div class="col-9">
                        <div style="height: 10vh" v-if="player">
                            <SpeedTimeChart :id="player.playerId + '-' + drillIndex" :player="player"
                                :startTime="startTime" :endTime="endTime" :sessionStartTime="sessionStartTime"
                                :sessionEndTime="sessionEndTime" :stepSize="calculateIntervals()" />
                        </div>
                    </div>
                    <div class="col-1 text-center">
                        <b-form-checkbox v-model="player.included" @change="deselectAllDrills" plain class="mx-auto">
                            {{ }}
                        </b-form-checkbox>
                    </div>
                </div>

                <EditModal :id="'drill-edit-modal'" :title="isEditing ? 'Edit Drill' : 'Create Drill'"
                    :cancelVariant="'secondary standard-btn'" :ok="isEditing ? updateDrill : addDrill"
                    :okTitle="isEditing ? 'Save Changes' : 'Confirm'" :okDisabled="isOkDisabled">
                    <div class="alert alert-danger" v-if="this.invalidTimeRangeText">
                        {{ this.invalidTimeRangeText }}
                    </div>

                    <label class="form-label">Drill Name</label>
                    <input v-model="newDrill.name" type="text" class="form-control mb-2" />

                    <label class="form-label">Time Period</label>
                    <div class="d-flex align-items-center">
                        <div class="d-flex align-items-center">
                            <span>Start</span>
                            <TimePicker input-class="form-control cursor-pointer border-0" class="mx-2"
                                v-model="startTime" @change="validateStartTime" format="HH:mm:ss" auto-scroll hide-clear-button>
                                <template v-slot:icon>
                                    <img :src="clockIcon" />
                                </template>
                            </TimePicker>
                        </div>
                        <div class="d-flex align-items-center">
                            <span>End</span>
                            <TimePicker input-class="form-control cursor-pointer border-0" class="mx-2"
                                v-model="endTime" @change="validateEndTime" format="HH:mm:ss" auto-scroll hide-clear-button>
                                <template v-slot:icon>
                                    <img :src="clockIcon" />
                                </template>
                            </TimePicker>
                        </div>
                    </div>
                    <p class="text-muted pt-2">Duration: {{ formattedDuration }} (mm:ss)</p>

                    <b-dropdown id="player-dropdown" text="Edit Players" class="mb-3" v-if="drillIndex != null">
                        <b-form-checkbox v-for="player in availablePlayers" plain :key="player.playerId"
                            :checked="players.some(p => p.playerId === player.playerId && p.included)"
                            @change="addIndividualPlayersToDrill(player)">
                            {{ player.playerName }}
                        </b-form-checkbox>
                    </b-dropdown>

                    <div v-for="(drill, index) in drills" :key="drill.id" class="drill-item py-1">
                        <b-form-checkbox plain :value="index" v-model="selectedDrills"
                            @change="includePlayersFromExistingDrills(index)">
                            Add Players From {{ drill.name }}
                        </b-form-checkbox>

                        <h6 class="pointer" style="color: var(--sportlight-teal); cursor: pointer"
                            @click="toggleDrillPlayersDisplay(index)">
                            {{ showPlayers[index] ? "Hide Players" : "Show Players" }}
                        </h6>

                        <div v-if="showPlayers[index]" class="p-0">

                            <span v-for="(player, idx) in playersFromExistingDrills[drill.id] || []"
                                :key="player.playerId">
                                <span class="text-muted">{{ player.name }}</span>
                                <span class="text-muted" v-if="idx < playersFromExistingDrills[drill.id].length - 1">,
                                </span>
                            </span>
                        </div>
                        <hr class="my-2">
                    </div>

                    <div class="drill-item py-1">
                        <b-form-checkbox plain v-model="includeAllPlayersInDrill" @change="toggleIncludeAllPlayers">
                            Include All Players
                        </b-form-checkbox>
                    </div>

                    <label class="form-label">Current Selection</label>
                    <p class="text-muted" v-if="includeAllPlayersInDrill">All Player From Session</p>
                    <p class="text-muted" v-else>
                        {{ includedPlayersNames.length > 0 ? includedPlayersNames.join(", ") : "No Player Selected" }}
                    </p>
                </EditModal>
            </div>
        </PageLayout>
    </div>
</template>

<script>
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import Colours from "@/utils/Colours";
import {UserData} from "@/components/UserData";
import DateUtils from "@/utils/DateUtils";
import { MeasurementTypes, getMeasurementUnit } from "@/utils/MeasurementSystem";
import UpdateSetting from "@/utils/UpdateSetting";
import { errorHandler } from "@/components/ErrorHandler";
import TimePicker from 'vue2-timepicker';
import 'vue2-timepicker/dist/VueTimepicker.css';
import clockIcon from "@/assets/feather/clock.svg";

export default {
    components: { VueSlider, TimePicker },
    data() {
        return {
            customerId: UserData.customerId(),
            showModal: false,
            isEditing: false,
            sessionStartTimeNanos: null,
            sessionEndTimeNanos: null,
            sessionStartTime: "00:00:00",
            sessionEndTime: "01:00:00",
            startTime: "00:00:00",
            endTime: "01:00:00",
            players: [],
            sessionPlayers: [],
            newDrill: { name: "" },
            sliderColour: Colours.SPORTLIGHT_TEAL,
            drillIndex: null,
            invalidTimeRangeText: "",
            showPlayers: {},
            selectedDrills: [],
            drills: [],
            playersFromExistingDrills: [],
            includeAllPlayersInDrill: false,
            speedUnit: getMeasurementUnit(MeasurementTypes.Speed),
            clockIcon
        };
    },
    computed: {
        includedPlayers() {
            return this.players.filter(p => p.included);
        },
        includedPlayersNames() {
            return this.includedPlayers.map((p) => p.playerName);
        },
        isOkDisabled() {
            return this.newDrill.name.trim() === "" || this.includedPlayers.length === 0;
        },
        formattedDuration() {
            const durationSeconds = this.timeStringToSeconds(this.endTime) - this.timeStringToSeconds(this.startTime);
            const minutes = Math.floor(durationSeconds / 60);
            const seconds = durationSeconds % 60;
            return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
        },
        sliderMarks() {
            const start = this.timeStringToSeconds(this.sessionStartTime);
            const end = this.timeStringToSeconds(this.sessionEndTime);
            const markInterval = this.calculateIntervals();
            const marks = {};
            for (let i = start; i <= end; i += markInterval) {
                marks[i] = this.formatTime(i);
            }
            marks[end] = this.formatTime(end); // ensures the last mark is exactly at end
            return marks;
        },
        availablePlayers() {
            return this.sessionPlayers.map(player => {
                const existingPlayer = this.players.find(p => p.playerId === player.playerId);
                return existingPlayer ? existingPlayer : player;
            });
        }
    },
    methods: {
        calculateIntervals() {
            const start = this.timeStringToSeconds(this.sessionStartTime);
            const end = this.timeStringToSeconds(this.sessionEndTime);
            return Math.ceil((end - start) / 4); // 4 is a fixed number of intervals to display & ensures even spacing
        },
        timeStringToSeconds(timeString) {
            const [hours, minutes, seconds] = timeString.split(':').map(Number);
            return hours * 3600 + minutes * 60 + seconds;
        },
        formatTime(value) {
            const hours = Math.floor(value / 3600);
            const minutes = Math.floor((value % 3600) / 60);
            const seconds = value % 60;
            return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
        },
        validateStartTime() {
            const start = this.timeStringToSeconds(this.startTime);
            const end = this.timeStringToSeconds(this.endTime);
            const sessionStart = this.timeStringToSeconds(this.sessionStartTime);
            const sessionEnd = this.timeStringToSeconds(this.sessionEndTime);

            if (start < sessionStart || start > sessionEnd || start > end) {
                this.startTime = this.sessionStartTime;
                this.invalidTimeRangeText = `Time must be between ${this.sessionStartTime} and ${this.sessionEndTime}`;
            } else {
                this.invalidTimeRangeText = "";
            }
        },
        validateEndTime() {
            const start = this.timeStringToSeconds(this.startTime);
            const end = this.timeStringToSeconds(this.endTime);
            const sessionStart = this.timeStringToSeconds(this.sessionStartTime);
            const sessionEnd = this.timeStringToSeconds(this.sessionEndTime);

            if (end < sessionStart || end > sessionEnd || end < start) {
                this.endTime = this.sessionEndTime;
                this.invalidTimeRangeText = `Time must be between ${this.sessionStartTime} and ${this.sessionEndTime}`;
            } else {
                this.invalidTimeRangeText = "";
            }
        },
        updateSliderRange([newStart, newEnd]) {
            this.startTime = this.formatTime(newStart);
            if (newEnd !== this.timeStringToSeconds(this.endTime)) {
                this.endTime = this.formatTime(newEnd);
            }
        },
        getDrillLabel(item) {
            return item?.name.toUpperCase();
        },
        handleDrillSelection(drill) {
            this.drillIndex = drill;
            this.newDrill.name = this.drills[this.drillIndex].name;
            const drillPlayerIds = this.playersFromExistingDrills[this.drills[this.drillIndex].id].map(player => player.playerId);
            this.players = this.sessionPlayers.filter(player => drillPlayerIds.includes(player.playerId)).map(player => ({ ...player, included: true }));
            this.startTime = DateUtils.convertNanoTimestampToTime(this.drills[this.drillIndex].startTime);
            this.endTime = DateUtils.convertNanoTimestampToTime(this.drills[this.drillIndex].endTime);
            this.selectedDrills = [drill];
            this.includeAllPlayersInDrill = false;
        },
        toggleDrillPlayersDisplay(index) {
            this.showPlayers = { ...this.showPlayers, [index]: !this.showPlayers[index] };
        },
        addIndividualPlayersToDrill(player) {
            this.deselectAllDrills();
            const existingPlayer = this.players.find(p => p.playerId === player.playerId);
            if (existingPlayer) {
                existingPlayer.included = !existingPlayer.included;
            } else {
                this.players.push({ ...player, included: true });
            }
        },
        includePlayersFromExistingDrills(index) {
            const drill = this.drills[index];
            if (!drill) return;

            const selectedDrillPlayers = new Set(this.playersFromExistingDrills[drill.id]?.map(p => p.playerId) || []);

            if (this.selectedDrills.includes(index)) {
                this.selectedDrills = this.selectedDrills.filter(i => i !== index);

                if (!this.includeAllPlayersInDrill) {
                    const remainingSelectedPlayers = new Set(
                        this.selectedDrills.flatMap(i => this.playersFromExistingDrills[this.drills[i].id]?.map(p => p.playerId) || [])
                    );
                    this.players.forEach(player => {
                        if (!remainingSelectedPlayers.has(player.playerId) && selectedDrillPlayers.has(player.playerId)) {
                            player.included = false;
                        }
                    });
                }
            } else {
                this.selectedDrills.push(index);
                this.players = this.players.map(player => ({
                    ...player,
                    included: selectedDrillPlayers.has(player.playerId) ? true : player.included,
                }));
                const existingPlayerIds = new Set(this.players.map(p => p.playerId));
                this.playersFromExistingDrills[drill.id]?.forEach(player => {
                    if (!existingPlayerIds.has(player.playerId)) {
                        this.players.push({ ...player, included: true });
                    }
                });
            }
        },
        toggleIncludeAllPlayers() {
            this.includeAllPlayersInDrill = !this.includeAllPlayersInDrill;
            if (this.includeAllPlayersInDrill) {
                this.players = this.sessionPlayers.map(player => ({
                    ...player,
                    included: true
                }));

                this.selectedDrills = this.drills.map((_, index) => index);
            } else {
                this.selectedDrills = [];
                this.players.forEach(player => (player.included = false));
            }
        },
        deselectAllDrills() {
            this.selectedDrills = [];
            this.includeAllPlayersInDrill = false;
        },
        resetDrill() {
            this.newDrill.name = "";
            this.startTime = this.sessionStartTime;
            this.endTime = this.sessionEndTime;
            this.drillIndex = null;
            this.players = this.sessionPlayers.map(player => ({...player, included: false}));
            this.deselectAllDrills();
        },
        editDrill() {
            const drill = this.drills[this.drillIndex];
            this.newDrill = {
                id: drill.id,
                name: this.newDrill.name || drill.name,
                startTime: this.startTime,
                endTime: this.endTime,
                drillPlayers: [...this.includedPlayers]
            };
            this.isEditing = true;
            this.showDrillModal();
        },
        async updateDrill() {
            const playerIds = this.includedPlayers.map(player => player.playerId);
            const updatedDrill = {
                drill: {
                    teamId: this.customerId,
                    sessionId: this.sessionId,
                    id: this.newDrill.id,
                    name: this.newDrill.name,
                    startTime: this.convertTimeToNanoTimestamp(this.sessionStartTimeNanos, this.sessionEndTimeNanos, this.startTime),
                    endTime: this.convertTimeToNanoTimestamp(this.sessionEndTimeNanos, this.sessionEndTimeNanos, this.endTime),
                },
                drillPlayerIds: playerIds,
            };
            const response = await this.$root.webApiPost("/updatedrill", updatedDrill);
            if (response.status === 200) {
                await this.getDrills();
                await this.getDrillPlayers();
                this.handleDrillSelection(this.drillIndex);
            } else {
                errorHandler.error(response, this);
            }
        },

        // minTime and maxTime are timestamp values in nanoseconds since the epoch. They MUST NOT be more than 24 hours apart.
        // timeString is a string in the format 'hh:mm:ss'. 
        // this function converts the timeString into a Date object that is in between minTime and maxTime, and returns
        // its value in nanoseconds since the epoch.
        convertTimeToNanoTimestamp(minTime, maxTime, timeString) {
            const [hours, minutes, seconds] = timeString.split(':').map(Number);
            const minDateTime = DateUtils.fromNanoTimestamp(minTime); // Convert to ms
            const maxDateTime = DateUtils.fromNanoTimestamp(maxTime); // Convert to ms

            const buildUtcDate = (baseDate) => new Date(Date.UTC(
                DateUtils.getYear(baseDate),
                DateUtils.getMonth(baseDate),
                DateUtils.getDate(baseDate),
                hours,
                minutes,
                seconds
            ));

            let newDate = buildUtcDate(minDateTime);

            if (newDate < minDateTime) {
                newDate = buildUtcDate(maxDateTime);
            }
            // TODO what to do if maxDateTime < newDate? this shouldn't happen, but it's worth checking and handling.
            if (maxDateTime < newDate) {
                console.log("newDate exceeds maxDateTime");
            }

            return newDate.getTime() * 1e6; // Return nanosecond timestamp
        },
        async addDrill() {
            const playerIds = this.includedPlayers.map(player => player.playerId);
            const json = {
                drill: {
                    teamId: this.customerId,
                    sessionId: this.sessionId,
                    name: this.newDrill.name,
                    startTime: this.convertTimeToNanoTimestamp(this.sessionStartTimeNanos, this.sessionEndTimeNanos, this.startTime),
                    endTime: this.convertTimeToNanoTimestamp(this.sessionEndTimeNanos, this.sessionEndTimeNanos, this.endTime)
                },
                drillPlayerIds: playerIds
            };            
            const response = await this.$root.webApiPost("/adddrill", json);
            if (response.status === 200) {
                this.newDrill.name = "";
                this.hideDrillModal();
                await this.getDrills();
                await this.getDrillPlayers();
                const latestDrillIndex = this.drills.length - 1;
                this.handleDrillSelection(latestDrillIndex);
            } else {
                errorHandler.error(response, this);
            }
        },
        async deleteDrill() {
            //if yes is selected on the browser pop up then delete drill
            if (!confirm("Are you sure you want to delete this drill?")) return;
            const drillId = this.drills[this.drillIndex]?.id;
            const response = await this.$root.webApiPost(
                `/deletedrill?drillId=${drillId}&teamId=${this.customerId}&sessionId=${this.sessionId}`
            );
            if (response.status === 200) {
                await this.getDrills();
                await this.getDrillPlayers();
                this.resetDrill();
            } else {
                errorHandler.error(response, this);
            }
        },
        async getSessionPlayers() {
            console.log("Get Session players");
            const response = await this.$root.webApiGet(
                `/timeHistories?customerId=${this.customerId}&sessionId=${this.sessionId}`
            );
            if (response.status === 200) {
                const playerHistories = response.data.playerHistories;
                const result = [];
                for (const playerHistory of playerHistories) {
                    result.push({
                        playerId: playerHistory.player.playerId,
                        playerName: playerHistory.player.name,
                        data: this.extractTimeHistory(playerHistory.timeHistory)
                    });
                }
                this.sessionPlayers = result;
                this.players = result;
            } else {
                errorHandler.error(response, this);
            }
        },
        extractTimeHistory(timeHistories) {
            // deliberately reduce the volume of results by a crude factor to aid performance on long session periods
            const reductionFactor = 10;
            const result = {};
            for (let i = 0; i < timeHistories.length; i += reductionFactor) {
                const speed = this.speedUnit.convert(timeHistories[i].smoothedSpeed);
                const time = DateUtils.convertNanoTimestampToTime(timeHistories[i].timestamp);
                result[time] = speed;
            }
            return result;
        },
        async getSession() {
            console.log("Get Session data");
            const response = await this.$root.webApiGet(
                `/session?customerId=${this.customerId}&sessionId=${this.sessionId}`
            );
            if (response.status === 200) {
                this.sessionStartTimeNanos = response.data.session.startTime;
                this.sessionEndTimeNanos = response.data.session.endTime;
                this.sessionStartTime = DateUtils.convertNanoTimestampToTime(this.sessionStartTimeNanos);
                this.sessionEndTime = DateUtils.convertNanoTimestampToTime(this.sessionEndTimeNanos);
                this.startTime = this.sessionStartTime;
                this.endTime = this.sessionEndTime;
            } else {
                errorHandler.error(response, this);
            }

        },
        async getDrills() {
            const response = await this.$root.webApiGet(
                `/drills?customerId=${this.customerId}&sessionId=${this.sessionId}`
            );
            if (response.status === 200) {
                this.drills = response.data;
            } else {
                errorHandler.error(response, this);
            }
        },
        async getDrillPlayers() {
            const response = await this.$root.webApiGet(
                `/drillplayers?customerId=${this.customerId}&sessionId=${this.sessionId}`
            );
            if (response.status === 200) {
                this.playersFromExistingDrills = response.data;
            } else {
                errorHandler.error(response, this);
            }
        },
        showDrillModal() {
            this.showModal = true;
        },
        hideDrillModal() {
            this.showModal = false;
        },
        async refreshData() {
            console.log("Refreshing data");
            await this.getSession();
            await this.getDrills();
            await this.getDrillPlayers();
            await this.getSessionPlayers();
        },
    },
    async mounted() {
        if (UserData.hasDrillManagement()) {
            await this.$root.executeTaskWithProgressBar(async () => {
                this.sessionId = this.$route.params.id;
                await this.refreshData();
                this.$root.newPageView("Drill Management Page", UserData.userName());
                UpdateSetting();
                console.log("DrillManagement.vue mounted");
            });
        } else {
            this.$root.logOut(3);
        }
    },
};
</script>

<style>
.vue__time-picker .dropdown ul li:not([disabled]).active,
.vue__time-picker .dropdown ul li:not([disabled]).active:hover {
    background: var(--sportlight-teal);
}
</style>