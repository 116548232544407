<template>
    <div>
        <span v-b-tooltip.hover title="Edit Player" @click="() => {showModal = true; displayPlayerIdentificationModal()}" >
            <img :src="editIcon" />
        </span>
        <EditModal
            :id="identificationModalId"
            :ok="displayPreviewChangesModal"
            cancelVariant='secondary standard-btn'
            title='Player Identification'
            size="lg"
            okTitle='Preview Changes'
            :okDisabled="isOkDisabled"
            :showModal="true"
            v-if="showModal"
        >
            <div class="player-identification-container">
                <Button  class="mb-2" type="primary" title="Restore Discard" :onClick="restoreDiscards" />
                <b-container>
                    <b-row v-if="!isDiscarded" class="mt-3">
                        <b-col class="pl-0">
                            <b-dropdown class="mr-2 mb-2"  id="player-dropdown" variant="outline-primary" :text="selectedPlayerName" right block >
                                <b-dropdown-item v-for="unusedPlayer in unusedPlayers" :key="unusedPlayer.playerId"
                                                @click="assignPlayer(playerThumbnail.playerId, unusedPlayer.playerId, unusedPlayer.name, playerThumbnail.base64EncodedThumbnail)">
                                    {{ unusedPlayer.name }}
                                </b-dropdown-item>
                            </b-dropdown>
                            <Button  class="mb-2" type="danger" title="Discard" :onClick="discardPlayer" />

                        </b-col>
                        <b-col>
                            <Base64EncodedImage class="" width="300px" height="300px" :base64EncodedImage="playerThumbnail.base64EncodedThumbnail" />
                        </b-col>
                    </b-row>
                </b-container>
            </div>
        </EditModal>

        <EditModal
            :id="saveModalId"
            :ok="saveIdentifiedPlayers"
            cancelVariant='secondary standard-btn'
            title='Confirm Changes'
            okTitle='Submit'
            :okDisabled= "!hasConfirmedChanges"
        >
            <b-alert  v-model="identificationFailed" variant="danger" >
                Unable to update player details. Please try again later.
            </b-alert>
            <div v-if="!playerNotIdentified">
                <h5 >Identified Player</h5>
                <div>
                    <div class="pb-2">
                        {{ identifiedPlayer.name }}
                    </div>
                    <Base64EncodedImage width="300px" height="300px" :base64EncodedImage="identifiedPlayer.thumbnail" />
                </div>
            </div>
            <div v-if="!noDiscards" >
                <h5 class="mt-3">Discarded Players</h5>
                <div class="mt-3">
                    <Base64EncodedImage width="300px" height="300px" :base64EncodedImage="playerThumbnail.base64EncodedThumbnail" />
                </div>
            </div>
            <Button :onClick="displayPlayerIdentificationModal" type="primary" title="Continue Editing" class="mt-4"/>
            <b-form-checkbox v-model="hasConfirmedChanges" plain class="mt-4">
                Yes, I have reviewed the changes and I'm satisfied.
            </b-form-checkbox>
        </EditModal>
    </div>
</template>

<script>
import EditModal from "../components/EditModal.vue";
import Button from "../components/Button.vue";
import Base64EncodedImage from "../components/Base64EncodedImage.vue";
import { errorHandler } from "@/components/ErrorHandler";
import editIcon from "@/assets/feather/edit-2.svg";

export default {
    components: {
        Base64EncodedImage,
        EditModal,
        Button
    },
    data() {
        return {
            identifiedPlayer: null,
            hasConfirmedChanges: false,
            selectedPlayerName: "Unidentified Player 1",
            isDiscarded: false,
            showModal: false,
            // the id needs to end in '-modal' so that the styles will work - see site.css
            // modals need to have a unique id to avoid behaviour described in CUST-531
            identificationModalId: "player-identification-" + this.playerThumbnail.playerId + "-modal",
            saveModalId: "save-identification-" + this.playerThumbnail.playerId + "-modal",
            identificationFailed: false,
            editIcon
        };
    },
    props: {
        customerId: String,
        sessionId: String,
        // (playerId, base64EncodedThumbnail) thumbnail of player involved in the session but not identified by ProcessFlow
        playerThumbnail: Object,
        // list of players (playerId, name) that are not included in the session (i.e. the user may recognise them as one of the unidentified players)
        unusedPlayers: Array,
        // hook to allow the calling page to be notified when the user has finished identifying players. Can be used to refresh the page
        onSaveSuccess: Function
    },
    methods: {
        assignPlayer(playerId, assignedPlayerId, name, thumbnail) {
            this.identifiedPlayer = {playerId, assignedPlayerId, name, thumbnail };

            this.selectedPlayerName = name;
        },
        discardPlayer() {
            this.identifiedPlayer = null;
            this.isDiscarded = true;
        },
        restoreDiscards(){
            this.isDiscarded = false;
        },
        displayPreviewChangesModal() {
            this.identificationFailed = false;
            this.$bvModal.show(this.saveModalId);
        },
        displayPlayerIdentificationModal() {
            this.hasConfirmedChanges = false;
            this.$bvModal.hide(this.saveModalId);
            this.$bvModal.show(this.identificationModalId);
        },
        async saveIdentifiedPlayers() {
            const toPlayerIdentification = (unidentifiedPlayerId, identifiedPlayerId) => {
                return {unidentifiedPlayerId, identifiedPlayerId};
            };

            let discardedPlayerIds = [];
            let identifiedPlayers = [];
            if (this.isDiscarded) {
                discardedPlayerIds = [this.playerThumbnail.playerId];
            } else {
                identifiedPlayers = [ toPlayerIdentification(this.identifiedPlayer.playerId, this.identifiedPlayer.assignedPlayerId) ];
            }


            const json = {customerId: this.customerId, sessionId: this.sessionId, discardedPlayerIds, identifiedPlayers};

            const response = await this.$root.webApiPost(
                `/playeridentifications?customerId=${this.customerId}&sessionId=${this.sessionId}`,
                json
            );

            if (response.status === 200) {
                await this.onSaveSuccess();
            } else if (response.status === 409) {
                this.identificationFailed = true;
                this.$bvModal.show(this.saveModalId);
            } else {
                errorHandler.error(response, this);
            }
        },

    },
    computed: {
        playerNotIdentified: function() { return this.identifiedPlayer === null; },
        noDiscards: function() { return !this.isDiscarded; },
        isOkDisabled: function() {return this.playerNotIdentified && this.noDiscards; }
    },
    async mounted() {
        this.selectedPlayerName = "Unidentified Player 1";
    }
};
</script>

<style scoped>
#player-dropdown /deep/ .dropdown-menu {
    height: 25vh;
    overflow-y: auto;
}
.player-identification-container {
    height: 35vh;
    overflow: hidden;
}
</style>