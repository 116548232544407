<template>
    <!-- prettier-ignore -->
    <div :style="{ opacity: !$root.isLoadingData ? 1 : 0 }" id="content-wrap" style="margin-left:90px">
        <SessionHeader />
        <PageLayout>
            <div class="section-container">
                <SectionTitle :title="'KPI Summary (Between Teams)'">
                    <MoreInfo :id="'team-kpi-summary-more-info'" :title="'KPI Summary (Between Teams)'">
                        <ul>
                            <li>KPI Summary provides a visual comparison between the team's current session and their
                                previous ones.
                                The coloured line represents the current session, while the box and whisker plot represents
                                the distribution of previous matches.</li>
                            <li>Total: Current session compared with all previous {{teamSessionReferenceGroup}} matches with at least 85 minutes of match time</li>
                            <BoxWhiskerAdditionalInfoText currentDataText="current session" referenceDataText="team's" />
                        </ul>
                    </MoreInfo>
                </SectionTitle>
                <MissingDataText v-if="!hasTeamKpi" message="Team KPI Summary Unavailable" />
                <div v-else style="width: 85vw;">
                    <OppositionKpiSummary
                    v-for="(kpi, index) in kpiSummaryLayouts"
                    :key="index"
                    :id="kpi.id"
                    :title="kpi.title"
                    :chartOptions="kpi.chartOptions"
                    :labelData="kpi.kpiData.labels"
                    :chartData="kpi.kpiData.kpi"/>
                </div>
            </div>
            <div class="section-container" v-if="isFullOppositionAnalysis">
                <SectionTitle :title="'Peak Demands (Between Teams)'">
                    <MoreInfo :id="'peak-demands-more-info'" :title="'Peak Demands (Between Teams)'">
                        Peak demands compares the peak outputs in this session for both the team and individual players
                        compared with {{oppositionPeakAnalysisReferenceGroup}} player averages.
                        The blue line shows the team data, the coloured lines show the players' data and the rainbow shows
                        the average peak demands from players during {{oppositionPeakAnalysisReferenceGroup}} matches.
                    </MoreInfo>
                </SectionTitle>
                <MissingDataText v-if="!teamKpis.teamSessionEpochs" :message="'Peak Demands Unavailable'" />
                <div v-else>
                    <div class="full-width-radio-button-container">
                        <RadioButton :id="'kpi-analysis-radio-group'" :modal="selectedRainbow" :options="rainbowOptions"
                            :name="'kpi-options'" @update-modal="selectedRainbow = $event" :task="toggleRainbow" />
                    </div>
                    <RainbowLegend :showBlueKey=true blueKeyText="Team Average" class="mt-4" />
                    <div class="row mt-4">
                        <div class="col-12 col-lg-6 d-flex flex-column mx-auto justify-content-center w-100">
                            <div class="d-flex align-items-center justify-content-center w-100">
                                <RadioButton :id="'team-position-radio-group'" :modal="teamPositionGroupSelected"
                                    :options="positionGroupOptions" :name="'team-position-options'"
                                    @update-modal="teamPositionGroupSelected = $event" :task="toggleOurRainbow" />
                            </div>
                            <div id="team-rainbow-container">
                                <canvas id="team-rainbow" class="team-rainbow-plot"> </canvas>
                            </div>
                        </div>
                        <div
                            class="col-12 col-lg-6 d-flex mt-4 mt-lg-0 flex-column mx-auto justify-content-center w-100">
                            <div class="d-flex align-items-center justify-content-center w-100">
                                <RadioButton :id="'opposition-position-radio-group'"
                                    :modal="oppositionPositionGroupSelected" :options="positionGroupOptions"
                                    :name="'opposition-position-options'"
                                    @update-modal="oppositionPositionGroupSelected = $event"
                                    :task="toggleOppositionRainbow" />
                            </div>
                            <div id="opposition-rainbow-container">
                                <canvas id="opposition-rainbow" class="opposition-rainbow-plot"> </canvas>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section-container" v-if="isFullOppositionAnalysis">
                <SectionTitle :title="'Individual Significant Turns'">
                    <MoreInfo :id="'turn-scatters-more-info'" :title="'Individual Significant Turns'">
                        Individual Significant Turns shows every significant turn made in the current session.
                        The plots show the entry speed, turn angle and time taken for each turn where the time is measured
                        between the end of the deceleration phase and the start of the acceleration phase. <br>
                    </MoreInfo>
                </SectionTitle>
                <EnhancedTurnIndicator />
                <div>
                    <div class="container-fluid">
                        <div class="row">
                            <TurnScattersLegend />
                        </div>
                        <div class="row mt-4">
                            <div class="col-12 col-lg-6">
                                <div class="col text-center mb-0">{{ this.teamName }}</div>
                                <TurnScattersChart style="height: 30vh;" class="col-12" id="team"
                                    :turns="scatterTurns(this.teamKpis.turns)" :minSpeed="1" :maxSpeed="maxTurnSpeed"
                                    :displayYAxis="true"
                                    :additionalTooltipText="this.turnsScattersAdditionalTooltipText"
                                    :maintainAspectRatio="false" :maxAngle=maxTurnAngle />
                                <p class="text-center pt-0 mt-0" style="font-size: 12px; height: 5vh;">Entry speed (<MeasurementUnit :type="Speed" />)
                                </p>
                            </div>
                            <div class="col-12 col-lg-6">
                                <div class="col text-center mb-0">{{ this.oppositionTeamName }}</div>
                                <TurnScattersChart style="height: 30vh;" id="opposition"
                                    :turns="scatterTurns(this.oppositionKpis.turns)" :minSpeed="1"
                                    :maxSpeed="maxTurnSpeed" :displayYAxis="true"
                                    :additionalTooltipText="this.turnsScattersAdditionalTooltipText"
                                    :maintainAspectRatio="false" :maxAngle=maxTurnAngle />
                                <p class="text-center pt-0 mt-0" style="font-size: 12px; height: 5vh;">Entry speed (<MeasurementUnit :type="Speed" />)
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 
            Only display Significant Turns Summaries for Standard licence customers
            Premium customers will be able to see this data on other tabs in a better format.
            -->
            <div v-if="!isFullOppositionAnalysis" class="section-container">
                <TurnRatesTitle :customerId="customerId" />
                <SectionTitle :title="teamName" style="padding-top: 2vh;" />
                <TurnRatesCharts :customerId="customerId" />
                <SectionTitle :title="oppositionTeamName" style="padding-top: 2vh;" />
                <TurnRatesCharts :customerId="customerId" :forOpposition="true" />
            </div>
            <div class="section-container">
                <SectionTitle :title="'KPI Summary (Within Players)'">
                    <MoreInfo :id="'players-kpi-summary-more-info'" :title="'KPI Summary (Within Players)'">
                        <ul>
                            <li> KPI Summary provides a visual comparison between the players' current session and their
                                previous ones.
                                The coloured line represents the current session, while the box and whisker plot represents
                                the distribution of previous matches.</li>
                            <li>Total: Current session compared with all previous {{playersSessionReferenceGroup}} matches with at least 85 minutes of match time</li>
                            <BoxWhiskerAdditionalInfoText currentDataText="current session" referenceDataText="players'" />
                            <li>(*) - played &lt; 90mins</li>
                        </ul>
                    </MoreInfo>
                </SectionTitle>
                <div style="margin-top:5vh">
                    <p style="font-size: 12px; margin-left: 5vw;">(*) - played &lt;90mins </p>
                </div>
                <div class="row w-100 mx-auto">
                    <div class="opposition-players-box-container row col-12 col-lg-6 w-100 mt-4 p-0">
                        <div class="d-flex row mx-auto w-100" v-if="distanceData">
                            <div class="col-4">
                                <BoxWhiskerLabel :id="'distance-chart-label'" :data="distanceData.labels"
                                    :options="distanceOptions" :width="playersLabelWidth" :bwHeight="playerBwHeight" />
                            </div>
                            <div class="col-8">
                                <BoxWhiskerChart :id="'distance-chart'" :data="distanceData.kpi"
                                    :options="distanceOptions" :width="playersPlotWidth" :bwHeight="playerBwHeight" />
                            </div>
                        </div>
                    </div>

                    <div class="opposition-players-box-container row col-12 col-lg-6 w-100 mt-4 p-0">
                        <div class="d-flex row mx-auto w-100" v-if="hsrData">
                            <div class="col-4">
                                <BoxWhiskerLabel :id="'hsr-chart-label'" :data="hsrData.labels" :options="hsrOptions"
                                    :width="playersLabelWidth" :bwHeight="playerBwHeight" />
                            </div>
                            <div class="col-8">
                                <BoxWhiskerChart :id="'hsr-chart'" :data="hsrData.kpi" :options="hsrOptions"
                                    :width="playersPlotWidth" :bwHeight="playerBwHeight" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row w-100 mx-auto mt-4">
                    <div class="opposition-players-box-container row col-12 col-lg-6 w-100 mt-4 p-0">
                        <div class="d-flex row mx-auto w-100" v-if="sprintDistanceData">
                            <div class="col-4">
                                <BoxWhiskerLabel :id="'sprint-distance-chart-label'" :data="sprintDistanceData.labels"
                                    :options="sprintDistanceOptions" :width="playersLabelWidth"
                                    :bwHeight="playerBwHeight" />
                            </div>
                            <div class="col-8">
                                <BoxWhiskerChart :id="'sprint-distance-chart'" :data="sprintDistanceData.kpi"
                                    :options="sprintDistanceOptions" :width="playersPlotWidth"
                                    :bwHeight="playerBwHeight" />
                            </div>
                        </div>
                    </div>

                    <div class="opposition-players-box-container row col-12 col-lg-6 w-100 mt-4 p-0">
                        <div class="d-flex row mx-auto w-100" v-if="turnData">
                            <div class="col-4">
                                <BoxWhiskerLabel :id="'turns-chart-label'" :data="turnData.labels"
                                    :options="turnOptions" :width="playersLabelWidth" :bwHeight="playerBwHeight" />
                            </div>
                            <div class="col-8">
                                <BoxWhiskerChart :id="'turns-chart'" :data="turnData.kpi" :options="turnOptions"
                                    :width="playersPlotWidth" :bwHeight="playerBwHeight" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PageLayout>
    </div>
</template>

<script>
import { errorHandler } from "@/components/ErrorHandler";
import { UserData } from "@/components/UserData";
import GetChartContext from "@/components/GetChartContext";
import DrawRainbowCurves from "@/components/RainbowCurves";
import { RainbowColours } from "@/components/RainbowColours";
import TurnRatesTitle from "./TurnRatesTitle.vue";
import TurnRatesCharts from "./TurnRatesCharts.vue";
import OppositionKpiSummary from "./OppositionKpiSummary.vue";
import { getSortedPlayerKpiBwData, getTeamKpiBwData } from "@/utils/BoxWhiskerKpiInfoGenerator";
import {
    getMeasurementUnit,
    convertBwData,
    MeasurementTypes,
    convertRainbowComparisonData
} from "@/utils/MeasurementSystem";
import UpdateSetting from "@/utils/UpdateSetting";
import store from "@/store/session";
import calculateMaxTurn from "@/utils/CalculateMaxTurn";
import BREAKPOINT_LG from "@/utils/Breakpoints";

export default {
    components: {
        TurnRatesTitle,
        TurnRatesCharts,
        OppositionKpiSummary
    },
    data() {
        return {
            ldUnit: getMeasurementUnit(MeasurementTypes.LongDistance),
            sdUnit: getMeasurementUnit(MeasurementTypes.ShortDistance),
            speedUnit: getMeasurementUnit(MeasurementTypes.Speed),
            Speed: MeasurementTypes.Speed,
            LongDistance: MeasurementTypes.LongDistance,
            ShortDistance: MeasurementTypes.ShortDistance,
            turns: [],
            isWIP: UserData.isWIP(),
            isFullOppositionAnalysis: UserData.hasFullOppositionAnalysis(),
            teamKpis: [],
            oppositionKpis: [],
            leagueComparisonData: [],
            oppositionPlayersKpis: [],
            teamName: '',
            oppositionTeamName: '',
            customerId: UserData.customerId(),
            selectedRainbow: 'distance',
            rainbowOptions: [
                { text: 'Distance', value: 'distance' },
                { text: 'HSR', value: 'hsr' },
                { text: 'Sprint', value: 'sprint' },
                { text: 'Turns', value: 'turns' },
            ],
            oppositionPlayersOptions: [],
            teamPositionGroupSelected: 0,
            oppositionPositionGroupSelected: 0,
            positionGroupOptions: [
                { text: 'All', value: 0 },
                { text: 'Defenders', value: 2 },
                { text: 'Midfielders', value: 3 },
                { text: 'Forwards', value: 4 },
            ],
            hasTeamKpi: true,
            playerBwHeight: 35,
            playersPlotWidth: window.innerWidth <= BREAKPOINT_LG ? 55 : 25,
            playersLabelWidth: 20,
            distanceData: null,
            hsrData: null,
            sprintDistanceData: null,
            turnData: null,
            teamDistanceData: null,
            teamHsrData: null,
            teamSprintDistanceData: null,
            teamTurnData: null,
            maxTurnSpeed: null,
            maxTurnAngle: null
        };
    },
    methods: {
        navigateTo(newPage) {
            this.$router.push(newPage).catch(() => { });
        },
        async refreshData() {
            await this.$root.executeTaskWithProgressBar(async () => {
                // get query string here
                this.sessionId = this.$route.params.id;
                await this.getKpis();
                await this.getTeamKpis();
                await this.getPlayerKpis();
                if (this.isFullOppositionAnalysis) {
                    await this.getTeamRainbow();
                    await this.getOppositionRainbow();
                }
                this.calculateTurnScattersMaxValues();
                UpdateSetting();
            });
        },
        async getKpis() {
            const response = await this.$root.webApiGet(
                `/oppositionanalysis?customerId=${this.customerId}&sessionId=${this.sessionId}&enablePerMinute=false`
            );
            if (response.status === 200) {
                this.teamName = response.data.ourData.name;
                this.oppositionTeamName = response.data.oppositionData.name;
                this.teamKpis = response.data.ourData;
                this.oppositionKpis = response.data.oppositionData;
                this.leagueComparisonData = response.data.leagueComparisonData;
            } else {
                errorHandler.error(response, this);
            }
        },
        turnsScattersAdditionalTooltipText(tooltipItem, data) {
            return [data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].playerName];
        },
        scatterTurns(turns) {
            if (turns) {
                return turns.map(t => {
                    return {
                        time: t.turn.twistingTimeS,
                        x: this.speedUnit.formatStandard(t.turn.entrySpeedMPerS),
                        y: t.turn.angleDegrees,
                        playerName: t.playerName
                    };
                });
            } else {
                return [];
            }
        },
        async getTeamKpis() {
            const team = this.teamKpis.kpis;
            const oppositionTeam = this.oppositionKpis.kpis;
            this.hasTeamKpi = (team && oppositionTeam) !== null;
            if (this.hasTeamKpi) {
                const teamsLabel = [this.teamName, this.oppositionTeamName];
                const distanceData = [convertBwData(team.totalDistanceM, this.ldUnit), convertBwData(oppositionTeam.totalDistanceM, this.ldUnit)];
                const hsrData = [convertBwData(team.totalHighSpeedDistanceM, this.sdUnit), convertBwData(oppositionTeam.totalHighSpeedDistanceM, this.sdUnit)];
                const sprintDistanceData = [convertBwData(team.totalSprintDistanceM, this.sdUnit), convertBwData(oppositionTeam.totalSprintDistanceM, this.sdUnit)];
                const turnsData = [team.totalTurns, oppositionTeam.totalTurns];

                this.teamDistanceData = getTeamKpiBwData(distanceData, teamsLabel);
                this.teamHsrData = getTeamKpiBwData(hsrData, teamsLabel);
                this.teamSprintDistanceData = getTeamKpiBwData(sprintDistanceData, teamsLabel);
                this.teamTurnData = getTeamKpiBwData(turnsData, teamsLabel);
            }
        },
        async getPlayerKpis() {
            const team = this.teamKpis.playerKpis;
            team.forEach(t => t.player.name = t.player.name + " \u2022"); //adds a bullet point to the team player names
            const oppositionTeam = this.oppositionKpis.playerKpis;
            const combineTeams = team.concat(oppositionTeam);

            this.distanceData = getSortedPlayerKpiBwData(combineTeams, 'activeDistanceM', (d) => convertBwData(d, this.ldUnit));
            this.hsrData = getSortedPlayerKpiBwData(combineTeams, 'highSpeedDistanceM', (d) => convertBwData(d, this.sdUnit));
            this.sprintDistanceData = getSortedPlayerKpiBwData(combineTeams, 'sprintDistanceM', (d) => convertBwData(d, this.sdUnit));
            this.turnData = getSortedPlayerKpiBwData(combineTeams, 'totalTurns', d => d);
        },
        getRainbowChart(rainbowData, teamChartCtx, positionGroup, teamName) {
            const teamData = rainbowData.teamSessionEpochs.teamData.playerValuesByKpiId;
            const playersData = rainbowData.playerSessionEpochs;
            const filteredPosition = positionGroup === 0 ? playersData : playersData.filter(t => t.player.positionGroupId === positionGroup);
            const comparison = this.leagueComparisonData.comparisonData ? this.leagueComparisonData.comparisonData.kpiEpochValues : null;
            const getPlayerKpi = (id, converter, formatter) => filteredPosition.map(t => { 
                return { 
                    name: t.player.name, 
                    values: t.playerData.playerValuesByKpiId[id].map(converter),
                    toolTips: t.playerData.playerValuesByKpiId[id].map(formatter),
                }; 
                }
            );

            const kpis = {
                distance: {
                    data: teamData.DISTANCE.map(v => this.sdUnit.convert(v.value)),
                    comparison: convertRainbowComparisonData(comparison.DISTANCE, this.sdUnit),
                    additionalData: getPlayerKpi('DISTANCE', d => this.sdUnit.convert(d.value), d => this.sdUnit.formatFine(d.value) + this.sdUnit.unitShort + '/min'),
                    limit: null,
                    title: 'Peak Distance Per Epoch', padding: 50, yLabel: 'Distance (' + this.sdUnit.unitShort + '/min)',
                    toolTips: teamData.DISTANCE.map(v => this.sdUnit.formatFine(v.value) + this.sdUnit.unitShort + '/min')
                },
                hsr: {
                    data: teamData.HSR.map(v => this.sdUnit.convert(v.value)),
                    comparison: convertRainbowComparisonData(comparison.HSR, this.sdUnit),
                    additionalData: getPlayerKpi('HSR', d => this.sdUnit.convert(d.value), d => this.sdUnit.formatFine(d.value) + this.sdUnit.unitShort + '/min'),
                    limit: null,
                    title: 'Peak HSR Per Epoch', padding: 50, yLabel: 'Distance (' + this.sdUnit.unitShort + '/min)',
                    toolTips: teamData.HSR.map(v => this.sdUnit.formatFine(v.value) + this.sdUnit.unitShort + '/min')
                },
                sprint: {
                    data: teamData.SPRINT.map(v => this.sdUnit.convert(v.value)),
                    comparison: convertRainbowComparisonData(comparison.SPRINT, this.sdUnit),
                    additionalData: getPlayerKpi('SPRINT', d => this.sdUnit.convert(d.value), d => this.sdUnit.formatFine(d.value) + this.sdUnit.unitShort + '/min'),
                    limit: null,
                    title: 'Peak Sprint Per Epoch', padding: 50, yLabel: 'Distance (' + this.sdUnit.unitShort + '/min)',
                    toolTips: teamData.SPRINT.map(v => this.sdUnit.formatFine(v.value) + this.sdUnit.unitShort + '/min')
                },
                turns: {
                    data: teamData.TURNS.map(v => v.value), comparison: comparison.TURNS, 
                    additionalData: getPlayerKpi('TURNS', d => d.value, d => d.value.toFixed(1) + 'N/min'), 
                    limit: null,
                    title: 'Peak Turns Per Epoch', padding: 1, yLabel: 'Turns (N/min)',
                    toolTips: teamData.TURNS.map(v => v.value.toFixed(1) + 'N/min')
                }
            };

            let selectedKpi = kpis[this.selectedRainbow];
            DrawRainbowCurves(teamChartCtx,
                teamName + ' - ' + selectedKpi.title,
                selectedKpi.comparison,
                selectedKpi.data,
                true,
                selectedKpi.limit,
                selectedKpi.padding,
                selectedKpi.yLabel,
                selectedKpi.additionalData,
                selectedKpi.toolTips
            );
        },
        async getTeamRainbow() {
            const teamChartCtx = GetChartContext('team-rainbow', 'team-rainbow-container', 'team-rainbow-plot');
            this.getRainbowChart(this.teamKpis, teamChartCtx, this.teamPositionGroupSelected, this.teamName);
        },
        async getOppositionRainbow() {
            const teamChartCtx = GetChartContext('opposition-rainbow', 'opposition-rainbow-container', 'opposition-rainbow-plot');
            this.getRainbowChart(this.oppositionKpis, teamChartCtx, this.oppositionPositionGroupSelected, this.oppositionTeamName);
        },
        toggleRainbow() {
            this.toggleOurRainbow();
            this.toggleOppositionRainbow();
        },
        toggleOurRainbow() {
            Array.from(document.getElementsByClassName('team-rainbow-plot')).forEach(e => e.remove());
            this.getTeamRainbow();
        },
        toggleOppositionRainbow() {
            Array.from(document.getElementsByClassName('opposition-rainbow-plot')).forEach(e => e.remove());
            this.getOppositionRainbow();
        },
        getPlayerOptions(title, precision) {
            return {
                tooltipLabel: "Current Match",
                showTicks: true,
                showTitle: true,
                title: title,
                precision: precision,
                labelPrecision: precision,
                averagePrecision: precision
            };
        },
        getTeamOptions(precision) {
            return {
                tooltipLabel: "Current Match",
                showTicks: false,
                showTitle: false,
                title: '',
                precision: precision,
                labelPrecision: precision,
                averagePrecision: precision
            };
        },
        calculateTurnScattersMaxValues() {
            if ((this.teamKpis.turns || this.oppositionKpis.turns)) {
                const teamTurns = this.teamKpis.turns.map(t => t.turn);
                const oppositionTurns = this.oppositionKpis.turns.map(t => t.turn);

                const maxTeamTurnSpeed = calculateMaxTurn(teamTurns, 'entrySpeedMPerS');
                const maxOppositionTurnSpeed = calculateMaxTurn(oppositionTurns, 'entrySpeedMPerS');

                const maxTeamTurnAngle = calculateMaxTurn(teamTurns, 'angleDegrees');
                const maxOppositionTurnAngle = calculateMaxTurn(oppositionTurns, 'angleDegrees');

                const maxSpeed = Math.max(maxTeamTurnSpeed, maxOppositionTurnSpeed);
                this.maxTurnSpeed = this.speedUnit.convert(maxSpeed >= 8 ? maxSpeed + 0.3 : 8);
                this.maxTurnAngle = Math.max(maxTeamTurnAngle, maxOppositionTurnAngle);
            }
        },
        updatePlotWidth() {
            this.playersPlotWidth = window.innerWidth <= BREAKPOINT_LG ? 55 : 25;
        }
    },
    async mounted() {
        await this.$root.executeTaskWithProgressBar(async () => {
            console.log("OppositionAnalysis.vue mounted");
            this.$root.newPageView("Opposition Analysis Page", UserData.userName());
            await this.refreshData();
            window.addEventListener("resize", this.updatePlotWidth);
            UpdateSetting();
        });
    },
    computed: {
        RainbowColours() {
            return RainbowColours;
        },
        distanceOptions() {
            return this.getPlayerOptions('Total Distance (' + this.ldUnit.unitShort + ')', this.ldUnit.decimalPlacesStandard);
        },
        sprintDistanceOptions() {
            return this.getPlayerOptions('Sprint (' + this.sdUnit.unitShort + ')', this.sdUnit.decimalPlacesStandard);
        },
        hsrOptions() {
            return this.getPlayerOptions('High Speed Distance (' + this.sdUnit.unitShort + ')', this.sdUnit.decimalPlacesStandard);
        },
        turnOptions() {
            return this.getPlayerOptions('Turns (n)', 0);
        },
        teamDistanceOptions() {
            return this.getTeamOptions(this.ldUnit.decimalPlacesStandard);
        },
        teamHsrOptions() {
            return this.getTeamOptions(this.sdUnit.decimalPlacesStandard);
        },
        teamTurnOptions() {
            return this.getTeamOptions(0);
        },
        teamSprintDistanceOptions() {
            return this.getTeamOptions(this.sdUnit.decimalPlacesStandard);
        },
        playersSessionReferenceGroup() {
            return store.state.playersSessionReferenceGroup;
        },
        teamSessionReferenceGroup() {
            return store.state.teamSessionReferenceGroup;
        },
        oppositionPeakAnalysisReferenceGroup() {
            return store.state.oppositionPeakAnalysisReferenceGroup;
        },
        kpiSummaryLayouts() {
            return [
                {
                    id: "total-distance",
                    title: `Total Distance (${this.ldUnit.unitShort})`,
                    chartOptions: this.teamDistanceOptions,
                    kpiData: this.teamDistanceData,
                },
                {
                    id: "total-hsr",
                    title: `Total HSR (${this.sdUnit.unitShort})`,
                    chartOptions: this.teamHsrOptions,
                    kpiData: this.teamHsrData,
                },
                {
                    id: "total-sprint-distance",
                    title: `Total Sprint Distance (${this.sdUnit.unitShort})`,
                    chartOptions: this.teamSprintDistanceOptions,
                    kpiData: this.teamSprintDistanceData,
                },
                {
                    id: "total-turn",
                    title: "Total Turns (n)",
                    chartOptions: this.teamTurnOptions,
                    kpiData: this.teamTurnData,
                },
            ];
        },
    }
};
</script>

<style scoped>
li {
    margin-bottom: 10px;
}

.kpi-charts-labels{
    width:10vw;
}

.opposition-players-box-main-container{
    display:flex;
    margin-top:5vh;
}
.opposition-players-box-container{
    overflow-y:auto;
    overflow-x:hidden;
    height:50vh;
    scrollbar-width: none;
}
.opposition-players-box{
    display:flex;
    height:60vh
}
</style>