<template>
    <div>
        <SectionTitle :title="'Sprint Counts Summary'">
            <MoreInfo :id="'sprint-count-info-modal'" :title="'Sprint Counts Summary'">
                <ul v-if="speedTypeThresholds">
                    <li>Sprint Counts Summary provides a visual comparison between the team’s & players’ current session
                        and their previous ones.
                        The coloured line represents the current session, while the box and whisker plot represents the
                        distribution of previous matches.</li>
                    <li>HSR captures distance covered
                        between
                        <MeasurementValue :type="Speed" :value=speedTypeThresholds.HSR :format="formatStandard" />
                        <MeasurementUnit :type="Speed" /> and
                        <MeasurementValue :type="Speed" :value=speedTypeThresholds.SPRINT :format="formatStandard" />
                        <MeasurementUnit :type="Speed" />
                    </li>
                    <li>Sprint captures distance covered above
                        <MeasurementValue :type="Speed" :value=speedTypeThresholds.SPRINT :format="formatStandard" />
                        <MeasurementUnit :type="Speed" />
                    </li>

                    <li>Total: Current session compared with all previous {{playersSessionReferenceGroup}} matches with
                        at least 85 minutes of match time {{getTotalText}}</li>
                    <BoxWhiskerAdditionalInfoText currentDataText="current session"
                        referenceDataText="team's & players'" />
                    <li>(*) - played &lt; 90mins</li>
                </ul>
            </MoreInfo>
        </SectionTitle>
        <MissingDataText v-if="!kpiCount || !teamKpiCount" :message="'Sprint Counts Unavailable'" />
        <div v-else>
            <MatchTimeIndicator />
            <div class="mx-auto" style="width: 50vw;">
                <div class="row d-flex w-50 mx-auto">
                    <RadioButton class="col-12 col-lg-6" :id="'speed-type-radio-group'" :modal="selectedSpeedTypeThreshold"
                        :options="speedTypeOptions" :name="'speed-type-options'"
                        @update-modal="selectedSpeedTypeThreshold = $event" :task="toggleSpeedType" />

                    <div class="col-12 col-lg-6">
                        <span>Sort by: </span>
                        <b-dropdown variant="outline-primary" :text="sortGroupOptions.find(t => t.value === selectedDistanceBandId).text">
                            <b-dropdown-item v-for="(option, index) in sortGroupOptions" v-bind:key="index"
                                @click="toggleSortGroup(option.value)" class="pt-2">
                                {{ option.text }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
            </div>
            <div>
                <div class="row p-0 w-100 g-0">
                    <div class="row col-lg-2 g-0" v-if="!isSmallerScreen">
                        <div class="col-12" v-if="teamKpiCount && sessionIsAMatch">
                            <BoxWhiskerLabel :id="'team-bw-label'" :data="teamKpiCount[defaultDistanceBandId].labels"
                                :options="defaultOptions" :width="labelWidth" :bwHeight="90" />
                        </div>
                        <div class="col-12">
                            <BoxWhiskerLabel :id="'player-bw-label'" :data="kpiCount[defaultDistanceBandId].labels"
                                :options="playersCountOptions" :width="labelWidth" :bwHeight="bwHeight"
                                v-if="kpiCount" />
                        </div>
                    </div>
                    <div v-for="layout in kpiLayouts" :key="layout.key" class="col-lg-2">
                        <KpiCountBWChart class="mt-4 mt-lg-0" :id="'team-' + layout.key"
                            :labelData="teamKpiCount[layout.key].labels" :labelOptions="defaultOptions"
                            :chartOptions="layout.teamChartOptions" :plotData="teamKpiCount[layout.key].kpi"
                            :bwWidth="plotWidth" :displayPlayerLabels="isSmallerScreen" :bwHeight="90" v-if="teamKpiCount && sessionIsAMatch" />

                        <KpiCountBWChart :id="layout.key" :labelData="kpiCount[layout.key].labels"
                            :labelOptions="playersCountOptions" :chartOptions="layout.playerChartOptions"
                            :plotData="kpiCount[layout.key].kpi" :matchClocks="matchClocks[layout.key]"
                            :matchClocksFormatter="MatchClocksFormatter" :bwWidth="plotWidth" :bwHeight="bwHeight"
                            :displayPlayerLabels="isSmallerScreen" v-if="kpiCount" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { errorHandler } from "@/components/ErrorHandler";
import { UserData } from "@/components/UserData";
import { getMeasurementUnit, MeasurementTypes, MeasurementUnit } from '@/utils/MeasurementSystem';
import { getPlayerKpiBwData } from "@/utils/BoxWhiskerKpiInfoGenerator";
import store from "@/store/session";
import MatchClocksFormatter from "@/utils/MatchClocksFormatter";
import KpiCountBWChart from './KpiCountBWChart';
import BREAKPOINT_LG from "@/utils/Breakpoints";

export default {
    components: {
        KpiCountBWChart
    },
    computed: {
        sessionIsAMatch() {
            return store.state.sessionIsAMatch;
        },
        playersSessionReferenceGroup() {
            return store.state.playersSessionReferenceGroup;
        },
        teamSessionReferenceGroup() {
            return store.state.teamSessionReferenceGroup;
        },
        getTotalText: function () {
            if (store.state.sessionIsAcademyMatch) {
                return `for the players and all previous ${this.teamSessionReferenceGroup} matches with at least 85 minutes of match time for the team`;
            }
            return null;
        },
        defaultOptions() {
            return {
                tooltipLabel: this.sessionIsAMatch ? "Current Match" : "Current Session",
                showTicks: false,
                showTitle: true,
                precision: 0,
                labelPrecision: 0,
                averagePrecision: 1,
            };
        },
        playersCountOptions() {
            return {
                ...this.defaultOptions,
                showTitle: !this.sessionIsAMatch,
                showTicks: true,
            };
        },
        titles() {
            return this.distanceBands.reduce((acc, { id, lowerBoundM, upperBoundM }) => {
                const formattedKey = this.formatSpeedTypeKey(this.selectedSpeedTypeThresholdKey());
                acc[id] = `${formattedKey} ${this.capitaliseFirstLetter(this.formatBandTitle(id, lowerBoundM, upperBoundM))}`;
                return acc;
            }, {});
        },
        sortGroupOptions() {
            return this.distanceBands.map(({ id, lowerBoundM, upperBoundM }) => ({
                text: this.formatBandTitle(id, lowerBoundM, upperBoundM, true),
                value: id
            }));
        },
        speedTypeOptions() {
            return ['SPRINT', 'HSR'].map(key => ({
                text: this.formatSpeedTypeKey(key),
                value: this.speedTypeThresholds[key]
            }));
        },
        bwHeight() {
            return this.kpiCount?.[this.defaultDistanceBandId]?.kpi.length <= 2 ? 110 : 55;
        },
        kpiLayouts() {
            return this.distanceBands.map(band => ({
                key: band.id,
                teamChartOptions: { ...this.defaultOptions, title: this.titles[band.id] },
                playerChartOptions: { ...this.playersCountOptions, title: this.titles[band.id] },
            }));
        },
        defaultDistanceBandId() {
            return this.distanceBands.length > 0 ? this.distanceBands[0].id : null;
        }
    },
    data() {
        return {
            Speed: MeasurementTypes.Speed,
            customerId: UserData.customerId(),
            isWIP: UserData.isWIP(),
            plotWidth: window.innerWidth <= BREAKPOINT_LG ? 65 : 15,
            isSmallerScreen: window.innerWidth <= BREAKPOINT_LG,
            labelWidth: 180,
            sprintCount: null,
            hsdCount: null,
            kpiCount: null,
            teamKpiCount: null,
            playersData: null,
            teamData: null,
            formatStandard: MeasurementUnit.prototype.formatStandard,
            measurementUnit: getMeasurementUnit(MeasurementTypes.ShortDistance),
            matchClocks: null,
            selectedDistanceBandId: null,
            distanceBands: null,
            speedTypeThresholds: null,
            selectedSpeedTypeThreshold: null,
        };
    },
    methods: {
        navigateTo(newPage) {
            this.$router.push(newPage).catch(() => { });
        },
        async refreshData() {
            await this.$root.executeTaskWithProgressBar(async () => {
                this.sessionId = this.$route.params.id;
                await this.getData();
                await this.getPlayerChartData();
                await this.getTeamChartData();
            });
        },
        async getData() {
            const response = await this.$root.webApiGet(
                `/kpicount?customerId=${this.customerId}&sessionId=${this.sessionId}`
            );
            if (response.status === 200) {
                this.teamData = response.data.teamKpiCount;
                this.playersData = response.data.playersKpiCount;
                this.distanceBands = response.data.distanceBands;
                this.speedTypeThresholds = response.data.speedTypeThresholds;
                if (this.distanceBands && this.distanceBands.length > 0) {
                    this.selectedDistanceBandId = this.distanceBands[0].id;
                }
                if (this.speedTypeThresholds) {
                    this.selectedSpeedTypeThreshold = this.speedTypeThresholds['SPRINT'] ||  this.speedTypeThresholds['HSR'];
                }
            } else {
                errorHandler.error(response, this);
            }
        },
        async getTeamChartData() {
            if (this.teamData) {
                const kpiCount = this.teamData.kpiCounts[this.selectedSpeedTypeThresholdKey()];
                this.teamKpiCount = this.distanceBands.reduce((acc, { id }) => {
                    const { value, average, standardDeviation } = kpiCount[id] || {};
                    if (value !== undefined) {
                        kpiCount[id].belowBounds = value < (average - standardDeviation);
                        kpiCount[id].aboveBounds = value > (average + standardDeviation);
                        acc[id] = { kpi: [kpiCount[id]], labels: ['Team Total'] };
                    }
                    return acc;
                }, {});
            }
        },
        async getPlayerChartData() {
            const playersKpiCount = this.playersData.map(t => ({ ...(t.kpiCounts[this.selectedSpeedTypeThresholdKey()]), ...t }));

            if (playersKpiCount.length > 0) {
                const sortedKpis = playersKpiCount.sort((a, b) => b[this.selectedDistanceBandId]?.value - a[this.selectedDistanceBandId]?.value);
                
                if (sortedKpis.length > 0) {
                    this.kpiCount = this.distanceBands.reduce((acc, { id }) => {
                        acc[id] = getPlayerKpiBwData(sortedKpis, id, d => d);
                        return acc;
                    }, {});
                }

                if (sortedKpis.length > 0) {
                    this.matchClocks = this.distanceBands.reduce((acc, { id }) => {
                        acc[id] = sortedKpis.map(p => p.sprintMatchClocks?.[id] || null);
                        return acc;
                    }, {});
                }
            }
        },
        capitaliseFirstLetter(string) {
            return string[0].toUpperCase() + string.slice(1).toLowerCase();
        },
        formatSpeedTypeKey(key) {
            return key === 'HSR' ? key : this.capitaliseFirstLetter(key);
        },
        selectedSpeedTypeThresholdKey() {
            return Object.keys(this.speedTypeThresholds).find(key => this.speedTypeThresholds[key] === this.selectedSpeedTypeThreshold);
        },
        formatBandTitle(id, lowerBoundM, upperBoundM, includeCountsText = false) {
            const countsText = includeCountsText ? 'Counts' : '';
            if (lowerBoundM !== null && upperBoundM !== null) {
                return `${this.measurementUnit.formatCoarse(lowerBoundM)}-${this.measurementUnit.formatCoarseUnit(upperBoundM)} ${countsText}`;
            } else if (lowerBoundM !== null && upperBoundM === null) {
                return `${this.measurementUnit.formatCoarseUnit(lowerBoundM)}+ ${countsText}`;
            } else {
                return `${id}`;
            }
        },
        toggleSpeedType() {
            this.getTeamChartData();
            this.getPlayerChartData();
            this.toggleSortGroup(this.defaultDistanceBandId); //reset sort by dropdown 
        },
        toggleSortGroup(p) {
            this.selectedDistanceBandId = p;
            this.getPlayerChartData();
        },
        MatchClocksFormatter,
        updateViewport() {
            this.plotWidth = window.innerWidth <= BREAKPOINT_LG ? 65 : 15;
            this.isSmallerScreen = window.innerWidth <= BREAKPOINT_LG;
        }
    },
    async mounted() {
        console.log("SessionKpiCount.vue mounted");
        window.addEventListener("resize", this.updateViewport);
        await this.refreshData();
    },
};
</script>
<style scoped>
li {
    margin-bottom: 10px;
}
</style>