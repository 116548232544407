<template>
    <div class="section-container">
        <SectionTitle :title="'Club Position Comparisons'">
            <MoreInfo :id="'sdn-player-kpi-more-info'" title='Club Position Comparisons'>
                <p>
                    Club Position Comparisons displays the selected player’s physical data (light blue) against the 
                    players within the position category from your own club (dark blue). This data is then 
                    contextualised within the wider data network shown as a box and whisker plot, with the whiskers 
                    representing the absolute maximum and minimum across the network, and the box the average (+/- 1 Z-Score)
                </p>
            </MoreInfo>
        </SectionTitle>
        <MissingDataText v-if="!hasData" :message="'Insufficient data for player'"/>
        <div class="row" v-else>
            <div class="player-bench-mark col-12 col-lg-3" v-if="distance">
                <BoxWhiskerChart 
                    :id="'player-kpi-distance'" :data="[distance]" :additionalData="teamDistance" :options="distanceOptions" :bwHeight="plotHeight" :width="plotWidth"/>
                <div class="ranking-container">
                    <p> Match Average: {{ distance.value }} (percentage%) </p>
                    <p> League Average: rank/total (percentage%) </p>
                </div>
            </div>
            <div class="player-bench-mark col-12 col-lg-3" v-if="hsr">
                <BoxWhiskerChart 
                    :id="'player-kpi-hsr'" :data="[hsr]" :additionalData="teamHsr" :options="hsrOptions" :bwHeight="plotHeight" :width="plotWidth"/>
                <div class="ranking-container">
                    <p> Match Average: {{ hsr.value }} (percentage%) </p>
                    <p> League Average: rank/total (percentage%) </p>
                </div>
            </div>
            <div class="player-bench-mark col-12 col-lg-3" v-if="sprintDistance">
                <BoxWhiskerChart 
                    :id="'player-kpi-sprint-distance'" :data="[sprintDistance]" :additionalData="teamSprintDistance" :options="sprintDistanceOptions" :bwHeight="plotHeight" :width="plotWidth"/>
                <div class="ranking-container">
                    <p> Match Average: {{ sprintDistance.value }}  (percentage%) </p>
                    <p> League Average: rank/total (percentage%) </p>
                </div>
            </div>
            <div class="player-bench-mark col-12 col-lg-3" v-if="turns">
                <BoxWhiskerChart 
                    :id="'player-kpi-turns'" :data="[turns]" :additionalData="teamTurns" :options="turnsOptions" :bwHeight="plotHeight" :width="plotWidth"/>
                <div class="ranking-container">
                    <p> Match Average: {{ turns.value }} (percentage%) </p>
                    <p> League Average: rank/total (percentage%) </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SectionTitle from "@/components/SectionTitle.vue";
import BoxWhiskerChart from "@/components/BoxWhiskerChart.vue";
import MissingDataText from '@/components/MissingDataText.vue';
import MoreInfo from "@/components/MoreInfo.vue";
import { convertBwData, getMeasurementUnit, MeasurementTypes } from '@/utils/MeasurementSystem';
import BREAKPOINT_LG from "@/utils/Breakpoints";

export default {
    props: {
        playerId: String,
        values: Object,
        teamValues: Array,
        comparisonValues: Object
    },
    components: {
        SectionTitle,
        BoxWhiskerChart,
        MissingDataText,
        MoreInfo
    },
    data() {
        return {
            ldUnit: getMeasurementUnit(MeasurementTypes.LongDistance),
            sdUnit: getMeasurementUnit(MeasurementTypes.ShortDistance),
            hasData: true,
            distance: null,
            hsr: null,
            sprintDistance: null,
            turns: null,
            teamDistance: [],
            teamHsr: [],
            teamSprintDistance: [],
            teamTurns: [],
            plotHeight: 120,
            plotWidth: window.innerWidth <= BREAKPOINT_LG ? 80 : 22,
        };
    },
    methods: {
        getKPIBenchMark() {
            const { distance, hsr, sprintDistance, turns } = this.values;
            this.hasData = distance !== null && hsr !== null && sprintDistance !== null && turns !== null;
            
            if (this.hasData) {
                const getBWData = kpis => {
                    const { value, average, standardDeviation } = kpis;
                    kpis.belowBounds = value < (average - standardDeviation);
                    kpis.aboveBounds = value > (average + standardDeviation);
                    return kpis;
                };
                this.distance = getBWData(convertBwData({ value: distance, ...this.comparisonValues.distance }, this.ldUnit));
                this.hsr = getBWData(convertBwData({ value: hsr, ...this.comparisonValues.hsr }, this.ldUnit));
                this.sprintDistance = getBWData(convertBwData({ value: sprintDistance, ...this.comparisonValues.sprintDistance }, this.ldUnit));
                this.turns = getBWData({ value: turns, ...this.comparisonValues.turns });
            }
            
            this.teamDistance = this.teamValues.map(t => { return { name: t.name, value: this.sdUnit.convert(t.kpiValues['DISTANCE']) }; });
            this.teamHsr = this.teamValues.map(t => { return { name: t.name, value: this.sdUnit.convert(t.kpiValues['HSR']) }; });
            this.teamSprintDistance = this.teamValues.map(t => { return { name: t.name, value: this.sdUnit.convert(t.kpiValues['SPRINT']) }; });
            this.teamTurns = this.teamValues.map(t => { return { name: t.name, value: t.kpiValues['TURNS'] }; });
        },
        getPlayersOptions(title, unit) {
            const dp = unit?.decimalPlacesStandard || 1;
            const unitLabel = unit ? `(${unit.unitShort})` : '';
            return {
                tooltipLabel: "Player Average",
                showTicks: false,
                showTitle: true,
                title: title + unitLabel,
                precision: dp,
                labelPrecision: dp,
                averagePrecision: dp,
                displayAxes: false
            };
        },
        updatePlotWidth() {
            this.plotWidth = window.innerWidth <= BREAKPOINT_LG ? 80 : 22;
        }
    },
    computed: {
        distanceOptions() {
            return this.getPlayersOptions('Distance', this.ldUnit);
        },
        hsrOptions() {
            return this.getPlayersOptions('HSR', this.sdUnit);
        },
        sprintDistanceOptions() {
            return this.getPlayersOptions('Sprint Distance', this.sdUnit);
        },
        turnsOptions() {
            return this.getPlayersOptions('Turns');
        },
    },
    async mounted() {
        this.getKPIBenchMark();
        window.addEventListener("resize", this.updatePlotWidth);
    }
};
</script>

<style scoped>
.ranking-container {
    font-size: 12px; 
    margin: 1vh auto;
    line-height:2px; 
    display:none;
    text-align:center; 
}
</style>