<template>
    <div :style="{ opacity: !$root.isLoadingData ? 1 : 0 }" id="content-wrap" style="margin-left:90px;">
        <PageHeader>
            <div class="d-flex justify-content-between">
                <PageHeaderTitle v-if="selectedPlayer">
                    <h2 class="player-name">
                        {{ selectedPlayer.name }}
                    </h2>
                    <span style="display:flex; align-items: baseline; height: auto">
                        <p style="margin-right: 5px">
                            {{ selectedPlayer.position }} | {{ selectedPlayer.shirtNumber ?
                            `#${selectedPlayer.shirtNumber}` :
                            'Unknown shirt number' }}
                        </p>
                    </span>
                </PageHeaderTitle>
                <div class="pt-4">
                    <DropdownSelector @item-selected="selectPlayer" :items="players" :getItemName="selectorDisplayText"
                        :index="selectedPlayerIdx" />
                </div>
            </div>
        </PageHeader>
        <MissingDataText v-if="!hasData" message="KPI Reporting Unavailable" />
        <div class="py-2 px-3" v-else>
            <div class="row justify-content-end">
                <div class="col-6 col-lg-2">
                    <RadioButton :id="'data-type-radio-group'" :modal="selectedDataType" :options="dataTypeOptions"
                        label="Data Type" :name="'kpi-options'" @update-modal="selectedDataType = $event"
                        :task="updateTableData" />
                </div>
                <div class="col-6 col-lg-2">
                    <RadioButton :id="'comparison-group-radio-group'" :modal="selectedComparisonGroup"
                        :options="comparisonGroupOptions" label="Comparison Options" :name="'comparison-group-options'"
                        @update-modal="selectedComparisonGroup = $event" :task="refreshData" />
                </div>
                <div class="col-6 col-lg-2">
                    <RadioButton :id="'position-filter-radio-group'" :modal="positionFilterActive" :options="positionOptions"
                         label="Position Options" :name="'position-filter-options'" @update-modal="positionFilterActive = $event"
                         :task="refreshData" />
                </div>
            </div>
            <div class="row">
                <div class="col text-end">
                    <ExportButton :onClick="generateCSV" />
                </div>
            </div>
            <div class="px-3 mx-auto">
                <KPITable :data="tableData" columnKey="sessionId" headingKey="name" :isPlayerTable=false
                    :kpiGroups="kpiGroups" />
            </div>
        </div>
    </div>
</template>

<script>
import KPITable from '../Session/KPITable.vue';
import ReportingKpiGroups from '@/utils/ReportingKpiGroups';
import { errorHandler } from "@/components/ErrorHandler";
import { UserData } from "@/components/UserData";
import { loadSelectedSquadIds } from "@/utils/Squad";
import ReportingTableDataGenerator from '@/utils/ReportingTableDataGenerator';
import generateReportingCSV from '@/utils/GenerateReportingCSV';

export default {
    components: {
        KPITable,
    },
    data() {
        return {
            customerId: UserData.customerId(),
            hasData: false,
            matchType: 'FULL_MATCH',
            selectedDataOverviewType: 'total',
            dataOverviewOptions: [
                { text: 'Total', value: 'total' },
                { text: 'In Play', value: 'inPlay' },

            ],
            selectedDataType: 'data',
            dataTypeOptions: [
                { text: 'Data', value: 'data' },
                { text: 'Percentile', value: 'percentile' }
            ],
            comparisonGroupOptions: [],
            selectedComparisonGroup: 1,
            positionFilterActive: false,
            positionOptions: [
                { text: 'All Positions', value: false },
                { text: 'Player Position', value: true }
            ],
            players: [],
            selectedPlayerIdx: 0,
            data: [],
            tableData: [],
            kpiGroups: ReportingKpiGroups
        };
    },
    methods: {
        async getPlayers() {
            const response = await this.$root.webApiGet(
                `/players?customerId=${this.customerId}`
            );

            if (response.status === 200) {
                const sortPlayers = response.data.sort(
                    (a, b) =>
                        a.name.localeCompare(b.name)
                );

                const selectedSquads = loadSelectedSquadIds();
                const isActive = sortPlayers.sort((a, b) => b.isActive - a.isActive);
                this.players = isActive.filter(t => selectedSquads.includes(t.squadId));
            } else {
                errorHandler.error(response, this);
            }
        },
        async getData(playerId) {
            const response = await this.$root.webApiGet(
                `/playerreporting?customerId=${this.customerId}&playerId=${playerId}&comparisonGroupId=${this.selectedComparisonGroup}&positionComparison=${this.positionFilterActive}`
            );
            if (response.status === 200) {
                this.data = response.data;
                this.hasData = this.data.playerData.length > 0;
            } else {
                errorHandler.error(response, this);
            }
        },
        async selectPlayer(p) {
            await this.$root.executeTaskWithProgressBar(async () => {
                this.selectedPlayerIdx = p;
                const playerId = this.players[this.selectedPlayerIdx].playerId;
                await this.getData(playerId);
                this.updateTableData();
            });
        },
        selectorDisplayText(player) {
            return player.name;
        },
        updateTableData() {
            this.tableData = ReportingTableDataGenerator.createTableData(this.data.playerData, this.selectedDataType, this.matchType);
            const additionalPlayerData = { playerName: this.selectedPlayer.name, position: this.selectedPlayer.positionShortDescription };
            this.tableData = this.tableData.map(data => ({ ...data, ...additionalPlayerData }));
            this.enableTableScroll();
        },
        enableTableScroll() {
            //Force table to scroll to most recent session
            const playerReportingTable = document.getElementById("player-reporting-table");
            if (playerReportingTable) {
                const width = playerReportingTable.clientWidth;
                playerReportingTable.scrollLeft += width * 200;
            }
        },
        setComparisonGroupOptions() {
            const availableOptions = this.data.comparisonGroupOptions;

            for (const id in availableOptions) {
                this.comparisonGroupOptions.push({ text: availableOptions[id].displayText, value: availableOptions[id].id });
            }
        },
        generateCSV() {
            const comparisonOption = this.comparisonGroupOptions.find(t => t.value === this.selectedComparisonGroup).text;
            const positionText = this.positionFilterActive ? '_by_position' : '';
            const fileName = `${this.selectedPlayer.name}_${this.matchType}_${this.selectedDataType}_compared_to_${comparisonOption}${positionText}.csv`;
            generateReportingCSV(this.tableData, this.selectedDataType, fileName, true);
        },
        async refreshData() {
            await this.$root.executeTaskWithProgressBar(async () => {
                const playerId = this.players[this.selectedPlayerIdx].playerId;
                await this.getData(playerId);
                this.updateTableData();
            });
        }
    },
    computed: {
        selectedPlayer() {
            return this.players[this.selectedPlayerIdx];
        } 
    },
    async mounted() {
        await this.$root.executeTaskWithProgressBar(async () => {
            await this.getPlayers();
            const playerId = this.players[this.selectedPlayerIdx].playerId;
            await this.getData(playerId);
            this.setComparisonGroupOptions();
            this.updateTableData();
            console.log("Player Reporting.vue mounted");
            this.$root.newPageView("Player Reporting Page", UserData.userName());
            setTimeout(() => { this.enableTableScroll(); }, 1);
        });
    },
};
</script>