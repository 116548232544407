<template>
    <div>
        <span v-b-tooltip.hover title="Edit Player" @click="editPlayer()" v-b-modal="player.playerId + '-player-edit-modal'">
            <img :src="editIcon" />
        </span>
            <EditModal
            :id="player.playerId + '-player-edit-modal'"
            :title="'Edit Player'"
            :cancel="cancelPlayerEdit"
            :ok="acceptPlayerEdit"
            :cancelVariant="'secondary standard-btn'"
            v-if="editPlayerModal">
            <fieldset>
                <div class="form-group">
                    <label for="edit-title">Player</label> <br>
                    <label for="edit-title">{{ player.name }}</label>
                </div>
                <div class="form-group" >
                    <label for="edit-type">Position</label>
                    <b-form-select id="edit-type" v-model="positionId">
                        <option v-for="p in positions" v-bind:key="p.positionId" :value="p.positionId">
                            {{ p.description }}
                        </option>
                    </b-form-select>
                </div>
                <div class="form-group">
                    <label for="edit-type">Squad</label>
                    <b-form-select id="edit-type" v-model="squadId">
                        <option v-for="s in squads" v-bind:key="s.squadId" :value="s.squadId">
                            {{ s.description }}
                        </option>
                    </b-form-select>
                </div>
                <div class="form-group" v-if="useShirtNumber">
                    <label for="edit-type">Shirt Number</label>
                    <b-form-select id="edit-type" v-model="shirtNumber">
                        <option v-for="s in shirtNumbers" v-bind:key="s.value" :value="s.value" :disabled="isShirtNumberDisabled(s.value)">
                            {{ s.text }}
                        </option>
                    </b-form-select>
                </div>
            </fieldset>
        </EditModal>
    </div>
</template>

<script>

import { errorHandler } from "./ErrorHandler";
import { UserData } from "./UserData";
import EditModal from "./EditModal.vue";
import DoubleZeroShirtNumber from "@/utils/DoubleZeroShirtNumber";
import configStore from "@/store/config";
import { toSquadOptions, getSelectableSquads } from "@/utils/Squad";
import editIcon from "@/assets/feather/edit-2.svg";

export default {
    props: {
        player: Object,
        refreshData: Function
    },
    components: {
        EditModal
    },
    data() {
        return {
            editPlayerModal: false,
            customerId: UserData.customerId(),
            positions: [],
            squadId: null,
            squads: toSquadOptions(getSelectableSquads()),
            positionId: null,
            useShirtNumber: UserData.useShirtNumber(),
            shirtNumber: null,
            shirtNumbers: [],
            players: null,
            editIcon
        };
    },
    methods: {
        async editPlayer() {
            this.editPlayerModal = true;
            this.positionId = this.player.positionSortOrder;
            this.squadId = this.player.squadId;
            this.shirtNumber = this.player.shirtNumber;
        },
        async acceptPlayerEdit() {
            this.editPlayerModal = true;
            const json = { playerId: this.player.playerId, customerId: this.customerId, 
                positionId: this.positionId, squadId: this.squadId, shirtNumber: this.shirtNumber };
            const response = await this.$root.webApiPost(`/player`, json);
            if (response.status === 200) {
                this.refreshData();
                if (this.useShirtNumber) {
                    this.shirtNumbers = [];
                    await this.getShirtNumbers();
                }
            } else {
                errorHandler.error(response, this);
            }
        },
        cancelPlayerEdit() {
            this.editPlayerModal = false;
        },
        async getPositions() {
            this.positions = configStore.state.positions;
        },
        async getShirtNumbers() {
            const response = await this.$root.webApiGet(
                `/players?customerId=${this.customerId}`
            );
            if (response.status === 200) {
                this.players = response.data.filter(t => t.isActive); // only get active players
                this.shirtNumber = this.player.shirtNumber; //selected player's shirt number
                this.shirtNumbers.push({ value: null, text: 'Select Shirt Number' }); //Default option for players without shirt number
                const allShirtNumbers = Array.from(Array(100).keys()).map(num => String(num)); //shirt numbers from 0-99
                allShirtNumbers.unshift('00'); //add 00 to the top of the array

                allShirtNumbers.forEach(shirtMarking => {
                    const value = shirtMarking === '00' ? DoubleZeroShirtNumber : shirtMarking;
                    const existingPlayer = this.players.find(p => (p.shirtNumber === parseInt(value)));
                    if (existingPlayer) {
                        this.shirtNumbers.push({ text: `${shirtMarking} - ${existingPlayer.name}`, value: value });
                    } else {
                        this.shirtNumbers.push({ text: shirtMarking, value: value });
                    }
                });
            } else {
                errorHandler.error(response, this);
            }
        },
        isShirtNumberDisabled(number) {
            //Disable selection of already assigned shirt numbers
            if (number !== null) {
                return this.players.find(p => p.shirtNumber === parseInt(number));
            }
        }
    },

    async mounted() {
        await this.getPositions();
        await this.getShirtNumbers();
    }
};
</script>