<template>
    <!-- prettier-ignore -->
    <PageHeader>
        <PageHeaderTitle>
            <h1>
                {{ displayDate(sessionDate) }}
                <span class="session-previous-fixture ml-2" v-if="previousFixtureOffset != null">+{{
                    previousFixtureOffset
                }}</span>
                <span class="session-next-fixture ml-2" v-if="nextFixtureOffset != null">-{{ nextFixtureOffset }}</span>
            </h1>
        </PageHeaderTitle>
        <ul class="nav nav-tabs" id="sub-page" role="tablist">
            <li class="nav-item" v-if="!useReportingScreen">
                <router-link :class="'kpioverview' === (this.$route.name) ? 'nav-link active' : 'nav-link'"
                    id="session-tab" data-toggle="tab" :to="'/session/' + customerId + '/' + sessionId" role="tab">
                    <span>KPI Overview</span>
                </router-link>
            </li>
            <li class="nav-item" v-else>
                <router-link :class="'sessionreporting' === (this.$route.name) ? 'nav-link active' : 'nav-link'"
                    id="session-tab" data-toggle="tab" :to="'/sessionreporting/' + customerId + '/' + sessionId" role="tab">
                    <span>Reporting</span>
                </router-link>
            </li>
            <li class="nav-item" v-if="!hideInaccessibleModules || hasSessionAnalysis">
                <router-link
                    class="nav-link"
                    :class="{ 'active' : '/session/sessionanalysis/'.includes(this.$route.name), 'disabled' : !this.hasSessionAnalysis  }"
                    :aria-disabled="!this.hasSessionAnalysis"
                    :tabindex="this.hasSessionAnalysis ? '' : -1"
                    id="session-speedtime-tab" data-toggle="tab"
                    :to="'/session/' + customerId + '/sessionanalysis/' + sessionId" role="tab">
                    Session Analysis
                </router-link>
            </li>

            <li class="nav-item" v-if="!hideInaccessibleModules || hasTurnAnalysis">
                <router-link
                    class="nav-link"
                    :class="{ 'active' : '/session/turnanalysis/'.includes(this.$route.name), 'disabled' : !this.hasTurnAnalysis }"
                    :aria-disabled="!this.hasTurnAnalysis"
                    :tabindex="this.hasTurnAnalysis ? '' : -1"
                    id="session-speedtime-tab" data-toggle="tab"
                    :to="'/session/' + customerId + '/turnanalysis/' + sessionId" role="tab">
                    Turn Analysis
                </router-link>
            </li>

            <li class="nav-item" v-if="hasOpposition && (!hideInaccessibleModules || hasOppositionAnalysis)">
                <router-link
                    class="nav-link"
                    :class="{ 'active' : '/session/oppositionanalysis/'.includes(this.$route.name), 'disabled' : !this.hasOppositionAnalysis  }"
                    :aria-disabled="!this.hasOppositionAnalysis"
                    :tabindex="this.hasOppositionAnalysis ? '' : -1"
                    id="session-speedtime-tab" data-toggle="tab"
                    :to="'/session/' + customerId + '/oppositionanalysis/' + sessionId" role="tab">
                    Opposition Analysis
                </router-link>
            </li>
            <li class="nav-item" v-if="!hideInaccessibleModules || hasPeakAnalysis">
                <router-link
                    class="nav-link"
                    :class="{ 'active' : '/session/peakanalysis/'.includes(this.$route.name), 'disabled' : !this.hasPeakAnalysis }"
                    :aria-disabled="!this.hasPeakAnalysis"
                    :tabindex="this.hasPeakAnalysis ? '' : -1"
                    id="session-speedtime-tab" data-toggle="tab"
                    :to="'/session/' + customerId + '/peakanalysis/' + sessionId" role="tab">
                    Peak Analysis
                </router-link>
            </li>
            <li class="nav-item" v-if="this.hasDrillManagement">
                <router-link
                    class="nav-link"
                    :class="{ 'active': '/session/drillmanagement/'.includes(this.$route.name), 'disabled': !this.hasDrillManagement }"
                    :aria-disabled="!this.hasDrillManagement"
                    :tabindex="this.hasDrillManagement ? '' : -1"
                    id="session-speedtime-tab" data-toggle="tab"
                    :to="'/session/' + customerId + '/drillmanagement/' + sessionId" role="tab">
                    Drill Management
                </router-link>
            </li>
        </ul>
    </PageHeader>
</template>
<script>
import { errorHandler } from "./ErrorHandler";
import { UserData } from '@/components/UserData';
import PageHeader from './PageHeader.vue';
import PageHeaderTitle from './PageHeaderTitle.vue';
import store from '../store/session';
import DateUtils from '@/utils/DateUtils';

export default {
    components: {
        PageHeader,
        PageHeaderTitle
    },
    data() {
        return {
            sessionDate: null,
            sessionId: 0,
            statusDisplayName: "",
            previousFixtureOffset: null,
            nextFixtureOffset: null,
            isWIP: UserData.isWIP(),
            hasOppositionAnalysis: UserData.hasOppositionAnalysis(),
            hasSessionAnalysis: UserData.hasSessionAnalysis(),
            hasTurnAnalysis: UserData.hasTurnAnalysis(),
            hasPeakAnalysis: UserData.hasPeakAnalysis(),
            hasOpposition:null,
            customerId: UserData.customerId(),
            useReportingScreen: UserData.useReportingForSession(),
            hideInaccessibleModules: UserData.hideInaccessibleModules(),
            hasDrillManagement: UserData.hasDrillManagement()
        };
    },
    methods: {
        navigateTo(newPage) {
            this.$router.push(newPage).catch(() => {});
        },
        async refreshData() {
            // get query string here
            this.sessionId = this.$route.params.id;
            this.$root.showWait = false;
            await this.getSession(); // get session info
            this.$root.showWait = false;
            
        },
        async getSession() {
            console.log("GET SESSION DATA");
            const response = await this.$root.webApiGet(
                `/session?customerId=${this.customerId}&sessionId=${this.sessionId}`
            );

            if (response.status === 200) {
                store.dispatch('loadSession', response.data);
                this.sessionDate = new Date(response.data.session.date);
                this.hasOpposition = response.data.session.opposition.customerId != null;
                this.sessionType = response.data.session.type;
                this.sessionTypeColour = response.data.session.typeColour;
                this.sessionTitle = response.data.session.title;
                const previousFixtureDate =
                    response.data.previousFixtureDate === null ? "" : new Date(response.data.previousFixtureDate);

                const nextFixtureDate =
                    response.data.nextFixtureDate === null ? "" : new Date(response.data.nextFixtureDate);
                if (previousFixtureDate) {
                    const prevTime = Math.abs(
                        this.sessionDate - previousFixtureDate
                    );
                    this.previousFixtureOffset = Math.ceil(
                        prevTime / (1000 * 60 * 60 * 24)
                    );
                }

                if (nextFixtureDate) {
                    const nextTime = Math.abs(
                        this.sessionDate - nextFixtureDate
                    );
                    this.nextFixtureOffset = Math.ceil(
                        nextTime / (1000 * 60 * 60 * 24)
                    );
                }
            } else {
                errorHandler.error(response, this);
            }
        },
        displayDate(date) {
            if (date) {
                return DateUtils.getOrdinalDate(date);
            } else {
                return "";
            }
        },
    },
    async mounted() {
        console.log("SessionHeader.vue mounted");
        this.refreshData();
    }
};

</script>