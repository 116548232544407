<template>
    <div :style="{ opacity: !$root.isLoadingData ? 1 : 0 }" id="content-wrap" style="margin-left:90px;">
        <SessionHeader />
        <div id="content" class="py-2 px-3">
            <div class="row mx-auto d-flex align-items-center">
                <div class="container-fluid col-4">
                    <div class="row">
                        <div class="col-12">
                            <h1 id="session-header" class="pb-4" v-if="session">
                                <span v-if="sessionIsAMatch">{{ customerName }} vs {{ oppositionName }} </span>
                                <span v-else>{{ sessionTitle }}</span>
                                <div class="pill ml-4" :style="{ borderColor: sessionTypeColour }">
                                    {{ sessionType }}
                                </div>
                            </h1>
                        </div>
                    </div>
                </div>
                <div class="row mx-auto col-8">
                    <div class="col-12 col-lg-3">
                        <RadioButton :id="'team-radio-group'" :modal="selectedTeam" :options="teamOptions" label="Team"
                            :name="'team-options'" @update-modal="selectedTeam = $event" :task="updateTableData" />
                    </div>
                    <div class="col-12 col-lg-2">
                        <RadioButton :id="'data-type-radio-group'" :modal="selectedDataType" :options="dataTypeOptions"
                            label="Data Type" :name="'kpi-options'" @update-modal="selectedDataType = $event"
                            :task="updateTableData" />
                    </div>
                    <div class="col-12 col-lg-3">
                        <RadioButton :id="'position-filter-radio-group'" :modal="positionFilterActive" :options="positionOptions"
                                     label="Position Options" :name="'position-filter-options'" @update-modal="positionFilterActive = $event"
                                     :task="refreshData" />
                    </div>
                    <div class="col-12 col-lg-4">
                        <RadioButton :id="'comparison-group-radio-group'" :modal="selectedComparisonGroup" :options="comparisonGroupOptions"
                            label="Comparison Options" :name="'comparison-group-options'" @update-modal="selectedComparisonGroup = $event"
                            :task="refreshData" />
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-between px-4">
                <MoreInfo :id="'session-reporting-more-info'" title="Session Reporting">
                    <p>
                        The Session Reporting screen offers an overview of the {{ teamDescriptor }} performance in this session. 
                        The current filters selected compare the Key Performance Indicators (KPIs) for every player to {{ comparisonGroupDescriptor }} players’ averages, 
                        which are derived from {{ matchTypeDescriptor }} datasets. Players are compared {{ positionFilterDescriptor }} positions, 
                        with colour-coded boxes indicating the percentile each player falls into:
                    <ul>
                        <li>
                            <div :style="{ backgroundColor: Colours.DARK_GREEN }" class="colour-description mr-2">
                            </div> &lt;= 10th percentile
                        </li>
                        <li>
                            <div :style="{ backgroundColor: Colours.BRIGHT_GREEN }" class="colour-description mr-2">
                            </div> &gt; 10th &amp; &lt;= 30th percentile
                        </li>
                        <li>
                            <div :style="{ backgroundColor: Colours.SPORTLIGHT_TEAL }" class="colour-description mr-2">
                            </div> &gt; 30th &amp; &lt;= 70th percentile
                        </li>
                        <li>
                            <div :style="{ backgroundColor: Colours.ORANGE }" class="colour-description mr-2">
                            </div> &gt; 70th &amp; &lt;= 90th percentile
                        </li>
                        <li>
                            <div :style="{ backgroundColor: Colours.RED }" class="colour-description mr-2">
                            </div> &gt; 90th percentile
                        </li>
                        <li>
                            Note: A black box (no colour code) is shown when a percentile is not applicable.
                        </li>
                    </ul>
                <br> As well as some widespread KPIs related to distance covered at specific thresholds for speed,
                acceleration and deceleration, the screen also displays the Fatigue Flag (FF), Hamstring Risk (HR), and
                Work Rate. FF and HR are unique to Sportlight, and the documentation related to these KPIs can be
                found
                <router-link class="learn-more" to="/whitepaper"> <span> here</span> </router-link>. <br> <br>
                Work Rate has been developed by Sportlight in conjunction with the Saudi Pro League. It is calculated
                using speed and acceleration fused using Osgnach’s (2010) calculation for energy cost. It provides an
                input into an equation that decays over time and is designed to be a close surrogate for heart rate.
                </p>
                </MoreInfo>
                <ExportButton :onClick="generateCSV" />
            </div>
            <MissingDataText v-if="!hasData" message="KPI Reporting Unavailable" />
            <div class="px-3 mx-auto" v-else>
                <KPITable :data="tableData" columnKey="playerId" headingKey="name" :isPlayerTable=true
                    :kpiGroups="kpiGroups" :isOpposition="selectedTeam === 'opposition'" />
            </div>
        </div>
    </div>
</template>

<script>
import KPITable from './KPITable.vue';
import { UserData } from "@/components/UserData";
import { errorHandler } from "@/components/ErrorHandler";
import ReportingKpiGroups from '@/utils/ReportingKpiGroups';
import store from '@/store/session';
import ReportingTableDataGenerator from '@/utils/ReportingTableDataGenerator';
import Colours from "@/utils/Colours";
import generateReportingCSV from '@/utils/GenerateReportingCSV';

export default {
    components: {
        KPITable
    },
    data() {
        return {
            customerId: UserData.customerId(),
            sessionId: this.$route.params.id, 
            selectedDataOverviewType: 'total',
            dataOverviewOptions: [
                { text: 'Total', value: 'total' },
                { text: 'In Play', value: 'inPlay' },
            ],
            selectedDataType: 'data',
            dataTypeOptions: [
                { text: 'Data', value: 'data' },
                { text: 'Percentile', value: 'percentile' }
            ],
            selectedTeam: 'current',
            teamOptions: [
                { text: 'Current', value: 'current', descriptionText: "team's" },
                { text: 'Opposition', value: 'opposition', descriptionText: "opposition's" }
            ],
            comparisonGroupOptions: [],
            selectedComparisonGroup: 1,
            positionFilterActive: false,
            positionOptions: [
                { text: 'All Positions', value: false, descriptionText: "across all" },
                { text: 'Player Position', value: true, descriptionText: "within their specific" }
            ],
            data: [],
            hasData: false,
            matchType: 'FULL_MATCH',
            matchTypeOptions: [
                { text: 'Full Match', value: 'FULL_MATCH', descriptionText: 'full match' },
            ],
            tableData: [],
            kpiGroups: ReportingKpiGroups,
            Colours: Colours
        };
    },
    methods: {
        navigateTo(newPage) {
            this.$router.push(newPage).catch(() => { });
        },
        async getData() {
            const response = await this.$root.webApiGet(
                `/sessionreporting?customerId=${this.customerId}&sessionId=${this.sessionId}&comparisonGroupId=${this.selectedComparisonGroup}&groupReferenceByPosition=${this.positionFilterActive}`
            );
            if (response.status === 200) {
                this.data = response.data;
            } else {
                errorHandler.error(response, this);
            }
        },
        updateTableData() {
            const data = this.selectedTeam === 'current' ? this.data.ourData : this.data.oppositionData;
            this.hasData = Array.isArray(data) && data.length > 0;
            if (this.hasData) {
                this.tableData = ReportingTableDataGenerator.createTableData(data, this.selectedDataType, this.matchType);
            }
        },
        setComparisonGroupOptions() {
            const availableOptions = this.data.comparisonGroupOptions;
            for (const id in availableOptions) {
                this.comparisonGroupOptions.push({ text: availableOptions[id].displayText, value: availableOptions[id].id, ...availableOptions[id] });
            }
        },
        generateCSV() {
            const comparisonOption = this.selectedComparisonGroupObject.text;
            const positionText = this.positionFilterActive ? '_by_position' : '';
            const fileName = `${this.sessionId}_${this.matchType}_${this.selectedDataType}_compared_to_${comparisonOption}${positionText}.csv`;
            generateReportingCSV(this.tableData, this.selectedDataType, fileName, false, this.sessionDate, this.sessionTitle);
        },
        async refreshData() {
            await this.$root.executeTaskWithProgressBar(async () => {
                await this.getData();
                this.updateTableData();
            });
        }
    },
    computed: {
        session() {
            return store.state.session?.session;
        },
        customerName() {
            return this.session.customerName;
        },
        oppositionName() {
            return this.session.opposition.customerName;
        },
        sessionType() {
            return this.session.type;
        },
        sessionTypeColour() {
            return this.session.typeColour;
        },
        sessionTitle() {
            return this.session.title;
        },
        sessionIsAMatch() {
            return this.session.sessionType.sessionIsAMatch;
        },
        sessionDate() {
            return this.session.date;
        },
        matchTypeDescriptor() {
            return this.matchTypeOptions.find(t => t.value === this.matchType).descriptionText;
        },
        teamDescriptor() {
            return this.teamOptions.find(t => t.value === this.selectedTeam).descriptionText;
        },
        selectedComparisonGroupObject() {
            return this.comparisonGroupOptions.find(t => t.value === this.selectedComparisonGroup);
        },
        comparisonGroupDescriptor() {
            return this.selectedComparisonGroupObject?.descriptionText;
        },
        selectedPositionFilterObject() {
            return this.positionOptions.find(t => t.value === this.positionFilterActive);
        },
        positionFilterDescriptor() {
            return this.selectedPositionFilterObject?.descriptionText;
        }
    },
    async mounted() {
        await this.$root.executeTaskWithProgressBar(async () => {
            await this.getData();
            this.setComparisonGroupOptions();
            this.updateTableData();
            console.log("Reporting.vue mounted");
            this.$root.newPageView("Session Reporting Page", UserData.userName());
        });
    },
};
</script>

<style scoped>
li {
    display: flex;
    width: 100%;
    align-items: center;

}
.colour-description{ 
    width: 20px; 
    height: 15px;
}
a {
    color: var(--sportlight-teal);
}
</style>