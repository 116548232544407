export default class DateUtils {
	static monthNames = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	];

    static getDay(date) {
		return new Date(date).getUTCDay();
    }
    
	static getDate(date) {
		return new Date(date).getUTCDate();
    }
    
	static getMonth(date) {
		return new Date(date).getUTCMonth();
	}

	static getYear(date) {
		return new Date(date).getUTCFullYear();
	}

    static getOrdinalDate(date) {
		const day = this.getDate(date);
		const month = this.getMonth(date);
		const year = this.getYear(date);
		let dateOrdinalSuffix;
		if (day > 0) {
			if ((day > 3 && day < 21) || day % 10 > 3) {
				dateOrdinalSuffix = "th";
			} else {
				// Day % 10 gives us the last digit of the day which determines the suffix
				const suffixes = ["th", "st", "nd", "rd"];
				dateOrdinalSuffix = suffixes[day % 10];
			}
		} else {
			dateOrdinalSuffix = "";
		}
		const dateOrdinal = day + dateOrdinalSuffix;
		return `${dateOrdinal} ${this.monthNames[month]} ${year}`;
	}

	static addDays(date, days) {
		let result = new Date(date);
		result.setUTCDate(result.getUTCDate() + days);
		return result;
	}

	static subtractDays(date, days) {
		let result = new Date(date);
		result.setUTCDate(result.getUTCDate() - days);
		return result;
	}

    static getMonthString(month) {
		return this.monthNames[month];
    }
    
    static getToday() {
        return new Date(new Date().setUTCHours(0, 0, 0, 0));
    }
    static subtractMonths = (date, months) => {
        let result = new Date(date);
        result.setUTCMonth(result.getUTCMonth() - months);
        // If the date's day has changed due to month subtraction adjust the date to the last day of the previous month
        if (result.getUTCDate() !== new Date(date).getUTCDate()) {
            result.setUTCDate(0);
        }
        return result;
    };

	static convertNanoTimestampToTime(nanoTimestamp) {
		const millis = Math.floor(nanoTimestamp / 1e6);
		// Format the time as "HH:mm:ss"
		return new Date(millis).toISOString().slice(11, 19);
    }
    
    static fromNanoTimestamp(nanoTimestamp) {
        const millis = Math.floor(nanoTimestamp / 1e6);
        return new Date(millis);
    }
}
