<template>
    <div :style="{opacity: !$root.isLoadingData ? 1 : 0}" id="content-wrap" style="margin-left:90px">
        <SessionHeader/>
        <PageLayout>
            <div class="section-container">
                <SectionTitle :title="getTitle">
                    <MoreInfo :id="'rainbows-info-modal'" :title="getTitle">
                        <p> {{ getInfoText }} </p>
                    </MoreInfo>
                </SectionTitle>

                <MissingDataText v-if="!hasRainbowCurvesData" :message="'Peak Demands Unavailable'"/>
                <div v-else>
                    <ExportButton :onClick="exportToCSV" />
                    <div class="full-width-radio-button-container" v-if="positionGroupSelected">
                        <RadioButton
                            :id="'peak-analysis-radio-group'"
                            :modal="positionGroupSelected"
                            :options="positionGroupOptions"
                            :name="'position-group-options'"
                            @update-modal="positionGroupSelected = $event"
                            :task="positionGroupChange"
                        />
                    </div>
                
                    <RainbowLegend :showBlueKey=true blueKeyText="Player Values" keyText="" />
                
                    <div id="peak-analysis-rainbow-curves-titles" class="peak-analysis-rainbow-curves-titles-container" v-if="!this.isBelowBreakpoint">
                        <h3 class="peak-analysis-rainbow-curves-title">Peak Distance Per Epoch</h3>
                        <h3 class="peak-analysis-rainbow-curves-title">Peak HSR Per Epoch</h3>
                        <h3 class="peak-analysis-rainbow-curves-title" v-if="showPeakAnalysisSprintRainbow">Peak Sprint
                            Per Epoch</h3>
                        <h3 class="peak-analysis-rainbow-curves-title">Peak Turns Per Epoch</h3>
                    </div>
                <div id="peak-analysis-rainbow-curves-container"
                    class="container"
                    :class="{'grid-template-columns-lg-5': showPeakAnalysisSprintRainbow, 'grid-template-columns-lg-4': !showPeakAnalysisSprintRainbow}">
                </div>
                </div>
            </div>

            <div class="section-container">
                <SectionTitle :title="'Peak Threshold Counts (Within Player)'">
                    <MoreInfo :id="'box-whisker-info-modal'" :title="'Peak Threshold Counts (Within Player)'">
                        <ul class="info-text">
                            <li>Peak Threshold Counts shows each player’s and the team total number of efforts completed 
                                above a set absolute peak threshold, for three epoch lengths. The coloured line represents 
                                the current session, while the box and whisker plot represents the distribution of previous 
                                {{playersSessionReferenceGroup}} matches with at least 85 minutes played {{getPeakThresholdText}}.</li>
                            <BoxWhiskerAdditionalInfoText currentDataText="current session" referenceDataText="team's & players'" />
                            <li>Thresholds are set to determine a peak period within each epoch, derived from league-wide representative data.</li>
                            <b-table hover :items="epochCountThresholdsTable" dark="dark"> </b-table>
                        </ul>
                    </MoreInfo>
                </SectionTitle>

                <MissingDataText v-if="!allPlayerPeakThresholds || allPlayerPeakThresholds.length === 0" :message="'Peak Threshold Counts Unavailable'"/>
                <div v-else>
                    <MatchTimeIndicator/>
                    
                    <div class="full-width-radio-button-container">
                        <RadioButton
                            :id="'peak-analysis-radio-group'"
                            :modal="epochSelected"
                            :options="epochRadioOptions"
                            :label="'Epoch Length'"
                            :name="'epoch-options'"
                            @update-modal="epochSelected = $event"
                            :task="epochChange"
                        />
                    </div>
                    <div class="row w-100 mx-auto">
                        <div class="row col-lg-2 mt-4" v-if="!isBelowBreakpoint">
                            <div class="col-12" v-if="teamPeakThresholds.length > 0 && sessionIsAMatch">
                                <BoxWhiskerLabel 
                                    :id="'peak-thresholds-team-bw-label'" 
                                    :data="['Team Total']"
                                    :options="teamLabelOptions" 
                                    :width="labelWidth"
                                    :bwHeight="90" />
                            </div>
                            <div class="col-12">
                                <BoxWhiskerLabel
                                    :id="'peak-thresholds-player-bw-label'"
                                    :data="playerPeakThresholds[0].labels"
                                    :options="playersOptions"
                                    :width="labelWidth"
                                    :bwHeight="bwHeight"
                                    v-if="playerPeakThresholds.length > 0"/>
                            </div>
                        </div>
                        <div class="row col-lg-10 p-0 m-0">
                            <div v-for="layout in peakThresholdLayouts" :key="layout.id" class="row col-12 mt-4 col-lg-3 g-0 p-0 mx-auto">
                                <KpiCountBWChart
                                    class="mt-4 mt-lg-0"
                                    :id="'team-' + layout.id"
                                    :labelData="['Team Total']"
                                    :labelOptions="teamLabelOptions"
                                    :chartOptions="layout.teamOptions"
                                    :plotData="teamPeakThresholds[layout.id]"
                                    :bwWidth="plotWidth"
                                    :bwHeight="90"
                                    :displayPlayerLabels="isBelowBreakpoint"
                                    v-if="teamPeakThresholds.length > 0 && sessionIsAMatch"/>
                                <KpiCountBWChart
                                    :id="'player-' + layout.id"
                                    :labelData="playerPeakThresholds[layout.id].labels"
                                    :labelOptions="playersOptions"
                                    :chartOptions="layout.playerOptions"
                                    :plotData="playerPeakThresholds[layout.id].data"
                                    :matchClocks="playerPeakThresholds[layout.id].matchTimes"
                                    :matchClocksFormatter="MatchClocksFormatter"
                                    :bwWidth="plotWidth"
                                    :bwHeight="bwHeight"
                                    :displayPlayerLabels="isBelowBreakpoint"
                                    v-if="playerPeakThresholds.length > 0"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PageLayout>
    </div>
</template>

<script>
import { errorHandler } from "@/components/ErrorHandler";
import { UserData } from "@/components/UserData";
import GetChartContext from "@/components/GetChartContext";
import DrawRainbowCurves from "@/components/RainbowCurves";
import { RainbowColours } from "@/components/RainbowColours";
import { KpiTypes } from "@/utils/KpiTypes";
import { getEpochCountThresholdsTable } from "@/utils/EpochCountThresholdsTable";
import {getMeasurementUnit, MeasurementTypes, convertBwData, convertRainbowComparisonData} from "@/utils/MeasurementSystem";
import UpdateSetting from "@/utils/UpdateSetting";
import store from "@/store/session";
import DateUtils from '@/utils/DateUtils';
import MatchClocksFormatter from "@/utils/MatchClocksFormatter";
import download from "@/utils/Download";
import KpiCountBWChart from './KpiCountBWChart';
import BREAKPOINT_LG from "@/utils/Breakpoints";

export default {
    components: {
        KpiCountBWChart
    },
    data() {
        return {
            shortDistanceMeasurementUnit: getMeasurementUnit(MeasurementTypes.ShortDistance),
            data: [],
            positionGroupSelected: '',
            positionGroupOptions: [],
            turns: 1,
            customerId: UserData.customerId(),
            hasRainbowCurvesData: true,
            getAllPositionGroups: true,
            epochSelected: '30',
            epochRadioOptions: [
                { text: '30s', value: '30' },
                { text: '60s', value: '60' },
                { text: '120s', value: '120' },
            ],
            labelWidth: 100,
            plotWidth: window.innerWidth <= BREAKPOINT_LG ? 60 : 15,
            isBelowBreakpoint: window.innerWidth < BREAKPOINT_LG,
            rainbowCurves: null,
            allTeamPeakThresholds: null,
            allPlayerPeakThresholds: null,
            teamPeakThresholds: [],
            playerPeakThresholds: [],
            useAllPlayers: UserData.useAllPlayers(),
            showPeakAnalysisSprintRainbow: UserData.showPeakAnalysisSprintRainbow(),
        };
    },
    methods: {
        navigateTo(newPage) {
            this.$router.push(newPage).catch(() => { });
        },
        async refreshData() {
            await this.$root.executeTaskWithProgressBar(async () => {
                this.sessionId = this.$route.params.id;
                await this.getData();
                await this.getPeakThresholdData();
            });
        },
        async getData() {
            const response = await this.$root.webApiGet(
                `/peakanalysis?customerId=${this.customerId}&sessionId=${this.sessionId}&useAllPlayers=${this.useAllPlayers}`
            );

            if (response.status === 200) {
                //First page load checks for available position groups, necessary for rehab sessions
                if (this.getAllPositionGroups) {
                    this.positionGroupOptions = response.data.rainbowPositionGroups;
                    if (this.positionGroupOptions.length > 0) { this.positionGroupSelected = this.positionGroupOptions[0].value; }
                    this.getAllPositionGroups = false;
                }

                this.rainbowCurves = response.data.rainbowCurves;
                this.hasRainbowCurvesData = this.rainbowCurves.playersData.length !== 0;
                await this.getRainbowCurveData();
                this.allTeamPeakThresholds = response.data.teamPeakThresholdCounts.kpiEpochCounts;
                this.allPlayerPeakThresholds = response.data.playerPeakThresholdCounts;
                await this.getPeakThresholdData();
                
            } else {
                errorHandler.error(response, this);
            }
        },
        async getRainbowCurveData() {
            const className = 'rainbow-plot';
            const chartContainer = 'peak-analysis-rainbow-curves-container';
            
            //Filter players by selected position group 
            const playersData = this.rainbowCurves.playersData.filter(t=> t.positionGroupId === this.positionGroupSelected);
            //Sort the players by session time, most at the top
            playersData.sort((a,b) => b.timePlayed - a.timePlayed);
            
            for (let i = 0; i < playersData.length; i++) {
                const playerData = playersData[i].playerValues.playerValuesByKpiId;
                const comparisonData = playersData[i].comparisonData;
                const playerName = playersData[i].playerName;
                
                const distanceLimit = Math.ceil(this.shortDistanceMeasurementUnit.convert(this.rainbowCurves.distanceLimit));
                const hsrLimit = Math.ceil(this.shortDistanceMeasurementUnit.convert(this.rainbowCurves.hsrLimit));
                const sprintLimit = Math.ceil(this.shortDistanceMeasurementUnit.convert(this.rainbowCurves.sprintLimit));
                const turnsLimit = Math.ceil(this.rainbowCurves.turnsLimit);
                
                const label = document.createElement("h1");
                label.className = 'peak-analysis-label';
                label.textContent = playerName;
                
                if (comparisonData === null) {
                    label.textContent = label.textContent.concat("*");
                    label.id = 'label' + i;
                    const hover = document.createElement("span");
                    hover.textContent = "Player requires minimum of 4 matches";
                    hover.className = 'peak-analysis-hover';

                    label.onmouseover = () => {
                        hover.style.display = "block";
                    };

                    label.onmouseleave = () => {
                        hover.style.display = "none";
                    };

                    document.getElementById(chartContainer).appendChild(label);
                    document.getElementById(label.id).appendChild(hover);
                } else {
                    document.getElementById(chartContainer).appendChild(label);
                }
                
                const distanceCanvas = document.createElement("canvas");
                const hsrCanvas = document.createElement("canvas");
                const turnsCanvas = document.createElement("canvas");

                distanceCanvas.id = "distance" + i;
                hsrCanvas.id = "hsr" + i;
                turnsCanvas.id = "turns" + i;
                
                const distanceChartCtx = GetChartContext(distanceCanvas.id, chartContainer, className);
                const hsrChartCtx = GetChartContext(hsrCanvas.id, chartContainer, className);
                
                const toolTips = (values, formatter) => values.map(v => {
                    const toolTips = [];
                    if (v.cumulativeProbability) {
                        toolTips.push(`${formatter(v.value)} (${v.cumulativeProbability.toFixed(1)}%)`);
                    } else {
                        toolTips.push(`${formatter(v.value)}`);
                    }

                    if (v.matchTime) { // only include matchTime if it is set
                        toolTips.push(`Match Time: ${v.matchTime}`);
                    }
                    return toolTips;
                    
                });

                const distanceUnit = this.shortDistanceMeasurementUnit.unitShort + '/min';
                
                // the ordering of the construction of the chartCtx variables is significant. They need to be created 
                // in the same order that they are displayed on the page.
                if (this.showPeakAnalysisSprintRainbow) {
                    const sprintCanvas = document.createElement("canvas");
                    sprintCanvas.id = "sprint" + i;
                    const sprintChartCtx = GetChartContext(sprintCanvas.id, chartContainer, className);
                    const sprintComparisonData = comparisonData ? convertRainbowComparisonData(comparisonData.kpiEpochValues.SPRINT, this.shortDistanceMeasurementUnit) : null;

                    DrawRainbowCurves(sprintChartCtx, 'Peak Sprint Per Epoch', sprintComparisonData,
                        playerData.SPRINT.map(v => this.shortDistanceMeasurementUnit.convert(v.value)), this.isBelowBreakpoint, sprintLimit, 25,
                        'Distance (' + distanceUnit + ')', null,
                        toolTips(playerData.SPRINT, v => this.shortDistanceMeasurementUnit.formatFine(v) + distanceUnit)
                    );
                }
                
                const turnsChartCtx = GetChartContext(turnsCanvas.id, chartContainer, className);

                
                const distanceComparisonData = comparisonData ? convertRainbowComparisonData(comparisonData.kpiEpochValues.DISTANCE, this.shortDistanceMeasurementUnit) : null;
                const hsrComparisonData = comparisonData ? convertRainbowComparisonData(comparisonData.kpiEpochValues.HSR, this.shortDistanceMeasurementUnit) : null;
                const turnsComparisonData = comparisonData ? comparisonData.kpiEpochValues.TURNS : null;

                DrawRainbowCurves(distanceChartCtx, 'Peak Distance Per Epoch', distanceComparisonData,
                    playerData.DISTANCE.map(v => this.shortDistanceMeasurementUnit.convert(v.value)), 
                    this.isBelowBreakpoint, distanceLimit, 50, 'Distance ('+distanceUnit + ')', null,
                    toolTips(playerData.DISTANCE, v => this.shortDistanceMeasurementUnit.formatFine(v) + distanceUnit)
                );

                DrawRainbowCurves(hsrChartCtx, 'Peak HSR Per Epoch', hsrComparisonData,
                    playerData.HSR.map(v => this.shortDistanceMeasurementUnit.convert(v.value)), 
                    this.isBelowBreakpoint, hsrLimit, 50, 'Distance ('+ distanceUnit +')', null,
                    toolTips(playerData.HSR, v => this.shortDistanceMeasurementUnit.formatFine(v) + distanceUnit)
                );

                DrawRainbowCurves(turnsChartCtx, 'Peak Turns Per Epoch', turnsComparisonData,
                    playerData.TURNS.map(v => v.value), 
                    this.isBelowBreakpoint, turnsLimit, 2, 'Turns (N/min)', null,
                    toolTips(playerData.TURNS, n => n.toFixed(1) + 'N/min')
                );
            }
        },
        async getPeakThresholdData() {
            const epochCountOptions = {
                30: 'countData30s',
                60: 'countData60s',
                120: 'countData120s'
            };

            const epochMatchTimeOptions = {
                30: '_30S',
                60: '_60S',
                120: '_120S',
            };

            const epochCountOptionSelected = epochCountOptions[this.epochSelected];
            const epochMatchTimeOptionSelected = epochMatchTimeOptions[this.epochSelected];
            
            for (let kpi in KpiTypes) {
                if (this.allTeamPeakThresholds[kpi] !== undefined) {
                    let peakThreshold;
                    if (KpiTypes.TURNS === KpiTypes[kpi] ) {
                        peakThreshold = this.allTeamPeakThresholds[kpi][epochCountOptionSelected];
                    } else {
                        peakThreshold = convertBwData(this.allTeamPeakThresholds[kpi][epochCountOptionSelected], this.shortDistanceMeasurementUnit);
                    }
                    const { value, average, standardDeviation } = peakThreshold;
                    peakThreshold.belowBounds = value < (average - standardDeviation);
                    peakThreshold.aboveBounds = value > (average + standardDeviation);
                    this.teamPeakThresholds.push([peakThreshold]);
                }
            }
            
            if (this.allPlayerPeakThresholds !== null && this.allPlayerPeakThresholds.length > 0) {
                const allPlayers = this.allPlayerPeakThresholds;

                //Sort players by position groups
                const sortedPlayers = allPlayers.sort(
                    (a, b) =>
                        a.player.positionGroupSortOrder - b.player.positionGroupSortOrder
                );
                
                //Inserts an array between position groups
                const groupedPlayers = sortedPlayers.reduce((t, i) => {
                        t[i.player.positionGroupSortOrder] = t[i.player.positionGroupSortOrder] || [];
                        t[i.player.positionGroupSortOrder].push(i);
                        return t;
                    }, Object.create(null));
                
                const playersData = [];
                const playersNames = [];
                const playersMatchTimes = [];
                const sessionTimes = sortedPlayers.map(p => p.sessionTime);
                const maxTime = Math.max(...sessionTimes) - 5;

                //Restructures the data and adds * for player names where required. Arrays containing numbers are added
                //between position groups to make gaps appear between the groups of players
                Object.values(groupedPlayers).forEach(player => {
                    playersData.push(player.map(t => playersData.push(t.peakThresholdCountMetrics)));
                    playersNames.push(player.map(t => playersNames.push(((t.sessionTime < maxTime) && this.sessionIsAMatch 
                    ? '* ' : '') + t.player.name)));
                    playersMatchTimes.push(player.map(t => playersMatchTimes.push(t.peakThresholdMatchTimes)));
                });
                
                //Remove the final empty arrays as this causes a gap at the bottom of the charts
                playersData.pop();
                playersNames.pop();
                playersMatchTimes.pop();

                //Blank data and empty name used to create a visible gap between groups of names/charts
                const blankData = { value: null, minimum: null, maximum: null, standardDeviation: null, average: null };
                const labels = playersNames.map(name => (typeof name) === 'string' ? name : '');
                
                for (let kpi in KpiTypes) {
                    const data = playersData
                        .map(player => {
                            if (player.kpiEpochCounts === undefined){
                              return blankData;
                            } else if (KpiTypes.TURNS === KpiTypes[kpi]) {
                              return player.kpiEpochCounts[kpi][epochCountOptionSelected];
                            } else {
                              return convertBwData(player.kpiEpochCounts[kpi][epochCountOptionSelected], this.shortDistanceMeasurementUnit);
                            }
                        })
                        .map(d => {
                            return {
                                belowBounds: (d.value < (d.average - d.standardDeviation)),
                                aboveBounds: (d.value > (d.average + d.standardDeviation)),
                                ...d
                            };
                        });
                    
                    const matchTimes = playersMatchTimes
                        .map(t => {
                            if (t && t.kpiEpochMatchTimes && t.kpiEpochMatchTimes[kpi]) {
                                return t.kpiEpochMatchTimes[kpi].epochMatchTimes[epochMatchTimeOptionSelected];
                            } else {
                                return null;
                            }
                        });
                    this.playerPeakThresholds.push({ data, labels, matchTimes });
                }
            }
        },
        resetRainbowCurvePlots() {
            //Clear old plots and player name labels before loading new ones
            Array.from(document.getElementsByClassName('rainbow-plot')).forEach(e => e.remove());
            Array.from(document.getElementsByClassName('peak-analysis-label')).forEach(e => e.remove());
            this.getRainbowCurveData();
        },
        positionGroupChange() {
            this.resetRainbowCurvePlots();
        },
        epochChange() {
            this.teamPeakThresholds = [];
            this.playerPeakThresholds = [];
            this.getPeakThresholdData();
        },
        exportToCSV() {
            this.rainbowCurves.playersData.sort((a, b) => {
                // first sort by position then by minuted played within position group
                const positionGroupComparison = a.positionGroupId - b.positionGroupId;
                return positionGroupComparison !== 0 ? positionGroupComparison : b.timePlayed - a.timePlayed;
            });

            const unit = `(${this.shortDistanceMeasurementUnit.unitShort}/min)`;
            const csvHeader = `Players,Session Title,Session Date,Epoch (s),Peak Distance ${unit},Peak HSR ${unit},` +
                `Peak Sprint ${unit},Peak Turns (N/min)`;
            const session = store.state.session.session;

            const csvContent = this.rainbowCurves.playersData.flatMap(p => {
                const playerName = p.playerName;
                const playerValues = p.playerValues.playerValuesByKpiId;
                const epochs = ["30", "60", "120", "180", "300", "600"];

                return epochs.reduce((acc, epoch) => {
                    if (!playerValues.DISTANCE[epochs.indexOf(epoch)]) {
                        return acc;
                    }
                    const distance = this.shortDistanceMeasurementUnit.formatFine(playerValues.DISTANCE[epochs.indexOf(epoch)].value);
                    const sprint = this.shortDistanceMeasurementUnit.formatFine(playerValues.SPRINT[epochs.indexOf(epoch)].value);
                    const hsr = this.shortDistanceMeasurementUnit.formatFine(playerValues.HSR[epochs.indexOf(epoch)].value);
                    const turns = playerValues.TURNS[epochs.indexOf(epoch)].value;
                    const sessionDate = DateUtils.getOrdinalDate(session.date);
                    const row = `${playerName}, ${session.title.replace(/,/g, ';')}, ${sessionDate}, ${epoch}, ${distance}, ${hsr}, ${sprint}, ${turns}`;
                    acc.push(row);
                    return acc;
                }, []);
            }).join('\n');

            const csvData = `${csvHeader}\n${csvContent}`;
            const blob = new Blob([csvData], { type: 'text/csv' });
            const file = `Peak Demands - (${session.title}-${session.date}).csv`;
            download(blob, file);
        },
        MatchClocksFormatter,
        updatePlotWidth() {
            this.plotWidth = window.innerWidth <= BREAKPOINT_LG ? 60 : 15;
            this.isBelowBreakpoint = window.innerWidth < BREAKPOINT_LG;
            this.resetRainbowCurvePlots();
        }
    },
    async mounted() {
        await this.$root.executeTaskWithProgressBar(async () => {
            console.log("PeakAnalysis.vue mounted");
            this.$root.newPageView("Peak Analysis Page", UserData.userName());
            window.addEventListener("resize", this.updatePlotWidth);
            await this.refreshData();
            UpdateSetting();
        });
    },
    computed: {
        sessionIsAMatch() {
            return store.state.sessionIsAMatch;
        },
        playersSessionReferenceGroup() {
            return store.state.playersSessionReferenceGroup;
        },
        teamSessionReferenceGroup() {
            return store.state.teamSessionReferenceGroup;
        },
        getPeakThresholdText: function () {
            if (store.state.sessionIsAcademyMatch) {
                return `for the players and all previous ${this.teamSessionReferenceGroup} matches with at least 85 minutes of match time for the team`;
            }
            return null;
        },
        RainbowColours() {
            return RainbowColours;
        },
        epochCountThresholdsTable() {
            return getEpochCountThresholdsTable();
        },
        teamLabelOptions() {
            return {
                ...this.defaultOptions,
                title: '',
            };
        },
        playersOptions() {
            return {
                ...this.defaultOptions,
                showTitle: !this.sessionIsAMatch,
                showTicks: true,
            };
        },
        defaultOptions() {
            return {
                tooltipLabel: this.sessionIsAMatch ? "Current Match" : "Current Session",
                showTicks: false,
                showTitle: true,
                precision: this.shortDistanceMeasurementUnit.decimalPlacesCoarse,
                averagePrecision: this.shortDistanceMeasurementUnit.decimalPlacesFine,
                labelPrecision: this.shortDistanceMeasurementUnit.decimalPlacesCoarse,

            };
        },
        titles() {
            return {
                distance: 'Distance (' + this.shortDistanceMeasurementUnit.unitShort + ')',
                hsr: 'HSR (' + this.shortDistanceMeasurementUnit.unitShort + ')',
                sprint: 'Sprint Distance (' + this.shortDistanceMeasurementUnit.unitShort + ')',
                turns: 'Turns (N)',
            };
        },
        teamDistanceOptions() {
            return {
                ...this.defaultOptions,
                title: this.titles.distance,
            };
        },
        teamHsrOptions() {
            return {
                ...this.defaultOptions,
                title: this.titles.hsr,
            };
        },
        teamSprintOptions() {
            return {
                ...this.defaultOptions,
                title: this.titles.sprint,
            };
        },
        teamTurnsOptions() {
            return {
                ...this.defaultOptions,
                precision: 0,
                averagePrecision: 1,
                labelPrecision: 0,
                title: this.titles.turns,
            };
        },
        playerDistanceOptions() {
            return {
                ...this.playersOptions,
                title: this.titles.distance,
            };
        },
        playerHsrOptions() {
            return {
                ...this.playersOptions,
                title: this.titles.hsr,
            };
        },
        playerSprintOptions() {
            return {
                ...this.playersOptions,
                title: this.titles.sprint,
            };
        },
        playerTurnsOptions() {
            return {
                ...this.playersOptions,
                title: this.titles.turns,
            };
        },

        getTitle: function() {
            return this.useAllPlayers ? 'Peak Demands (All Players)' : 'Peak Demands (Within Player)';
        },
        getInfoText: function() {
            return this.useAllPlayers ? 'Peak demands compares an individual athletes peaks from this session with data ' +
                'from all other athletes. The blue line shows the current sessions data and the rainbow is the comparison data, ' +
                'which comes from all athletes data across all sessions' 
                : 
                'Peak Demands compares each players peaks from this session with ' +
                'their own previous data. The blue line shows current session data and the rainbow is comparison data, which ' +
                `comes from all previous ${this.playersSessionReferenceGroup} matches with at least 85 minutes of match time. A player requires at ` +
                'least 4 full matches to have comparison data.';
        },
        bwHeight() {
            return this.playerPeakThresholds[0].data.length <= 2 ? 110 : 60;
        },
        peakThresholdLayouts() {
            return [
                {
                    id: 0,
                    teamOptions: this.teamDistanceOptions,
                    playerOptions: this.playerDistanceOptions,
                },
                {
                    id: 1,
                    teamOptions: this.teamHsrOptions,
                    playerOptions: this.playerHsrOptions,
                },
                {
                    id: 2,
                    teamOptions: this.teamSprintOptions,
                    playerOptions: this.playerSprintOptions,
                },
                {
                    id: 3,
                    teamOptions: this.teamTurnsOptions,
                    playerOptions: this.playerTurnsOptions,
                }
            ];
        }
        
    }
};
</script>

<style>
#peak-analysis-rainbow-curves-container {
    display: grid;
    justify-content: center;
    padding: 1.5em;
}

.peak-analysis-label {
    font-size:14px;
    text-align:left;
    padding:15px;
    white-space:nowrap;
    position: relative;
    margin: auto 0;
    height: 10vh;
}

.peak-analysis-hover {
    display: none;
    position: absolute;
    top: -40px;
    width: 100px;
    font-size: 14px;
    text-align: center;
    background-color: #383E42;
    white-space:pre-wrap;
}

.peak-analysis-rainbow-curves-titles-container {
    display: flex;
    justify-content: space-evenly;
    width: 107%;
    align-items: center;
    padding-top: 6vh;
    margin-left: 3vh;
}

.peak-analysis-rainbow-curves-title {
    font-size: 16px;
    align-items: center;
    font-weight: bold;
}

.grid-template-columns-lg-5 {
    grid-template-columns: repeat(5, 1fr);
    width: 22vw;
}
.grid-template-columns-lg-4 {
    grid-template-columns: repeat(4, 1fr);
    width: 28vw;
}

/* Same width as BREAKPOINT_LG */
@media (max-width: 992px) {
    .peak-analysis-label {
        font-size:16px;
        text-align: center;
        height: auto;
    }
    .container {
        grid-template-columns: 1fr;
        width: 100%;
        gap: 1.5em;
    }
}
</style>