<template>
    <div class="section-container w-100">
        <SectionTitle :title="'Peak Demands'">
            <MoreInfo :id="'rehab-peak-demands-more-info'" title='Peak Demands'>
                <p>
                    Peak Demands compares each player's peaks (blue line) from their most recent rehab session to
                    their historic performances where they have played a minimum of 45 minutes (rainbow).
                    A player must have at least 4 matches to be compared with their own historic performances
                    otherwise the comparison is made to positionally matched players in the {{ league }}.
                </p>
            </MoreInfo>
        </SectionTitle>
        <MissingDataText v-if="!hasPeakDemands" :message="'Peak Demands Unavailable'" />
        <div v-else>
            <RainbowLegend :showBlueKey=true
                :keyText="data.isUsingPlayerComparisonData ? 'Player ' : league + ' Position '"
                :blueKeyText="playerName" />
            <div class="row mx-auto p-4">
                <div class="col-12 col-lg-6 mt-2" id="distance-rainbow-container">
                    <canvas id="distance-rainbow" class="rainbow-plot"> </canvas>
                </div>
                <div class="col-12 col-lg-6 mt-2" id="hsr-rainbow-container">
                    <canvas id="hsr-rainbow" class="rainbow-plot"> </canvas>
                </div>
            </div>
            <div class="row mx-auto p-4">
                <div class="col-12 col-lg-6 mt-2" id="sprint-rainbow-container">
                    <canvas id="sprint-rainbow" class="rainbow-plot"> </canvas>
                </div>
                <div class="col-12 col-lg-6 mt-2" id="turns-rainbow-container">
                    <canvas id="turns-rainbow" class="rainbow-plot"> </canvas>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DrawRainbowCurves from "@/components/RainbowCurves";
import GetChartContext from "@/components/GetChartContext";
import SectionTitle from "@/components/SectionTitle";
import RainbowLegend from "@/components/RainbowLegend.vue";
import MissingDataText from "@/components/MissingDataText.vue";
import MoreInfo from "@/components/MoreInfo.vue";
import { UserData } from "@/components/UserData";
import store from "./store";
import { getMeasurementUnit, MeasurementTypes, convertRainbowComparisonData } from "@/utils/MeasurementSystem";

export default {
    props: {
        data: Object,
    },
    components: {
        SectionTitle,
        RainbowLegend,
        MoreInfo,
        MissingDataText
    },
    data() {
        return {
            playerName: null,
            measurementUnit: getMeasurementUnit(MeasurementTypes.ShortDistance),
            league: UserData.teamType() === 'm' ? 'EPL' : 'WSL',
            hasPeakDemands: false,
            charts: {
                distance: null,
                hsr: null,
                sprint: null,
                turns: null,
            },
        };
    },
    methods: {
        async getPeakDemands() {
            const { playerData, comparisonData } = await this.data;
            if (this.hasPeakDemands) {
                const className = 'rainbow-plot';
                const hsrChartCtx = GetChartContext('hsr-rainbow', 'hsr-rainbow-container', className);
                const distanceChartCtx = GetChartContext('distance-rainbow', 'distance-rainbow-container', className);
                const sprintChartCtx = GetChartContext('sprint-rainbow', 'sprint-rainbow-container', className);
                const turnsChartCtx = GetChartContext('turns-rainbow', 'turns-rainbow-container', className);

                const distanceComparisonData = comparisonData ? convertRainbowComparisonData(comparisonData.distancePeakPerEpoch, this.measurementUnit) : null;
                const hsrComparisonData = comparisonData ? convertRainbowComparisonData(comparisonData.hsrPeakPerEpoch, this.measurementUnit) : null;
                const sprintComparisonData = comparisonData ? convertRainbowComparisonData(comparisonData.sprintPeakPerEpoch, this.measurementUnit) : null;
                const turnsComparisonData = comparisonData ? comparisonData.turnsPeakPerEpoch : null;

                this.charts.distance = DrawRainbowCurves(distanceChartCtx, 'Peak Distance Per Epoch', distanceComparisonData,
                    playerData.averageData.distancePeakPerEpoch.map(v => this.measurementUnit.convert(v)), true, null,
                    50, 'Distance (' + this.measurementUnit.unitShort + '/min)', null,
                    playerData.averageData.distancePeakPerEpoch.map(v => this.measurementUnit.formatFine(v) + this.measurementUnit.unitShort + '/min')
                );
                this.charts.hsr = DrawRainbowCurves(hsrChartCtx, 'Peak HSR Per Epoch', hsrComparisonData,
                    playerData.averageData.hsrPeakPerEpoch.map(v => this.measurementUnit.convert(v)), true, null,
                    50, 'Distance (' + this.measurementUnit.unitShort + '/min)', null,
                    playerData.averageData.hsrPeakPerEpoch.map(v => this.measurementUnit.formatFine(v) + this.measurementUnit.unitShort + '/min'));
                this.charts.sprint = DrawRainbowCurves(sprintChartCtx, 'Peak Sprint Per Epoch', sprintComparisonData,
                    playerData.averageData.sprintPeakPerEpoch.map(v => this.measurementUnit.convert(v)), true, null,
                    20, 'Distance (' + this.measurementUnit.unitShort + '/min)', null,
                    playerData.averageData.sprintPeakPerEpoch.map(v => this.measurementUnit.formatFine(v) + this.measurementUnit.unitShort + '/min'));
                this.charts.turns = DrawRainbowCurves(turnsChartCtx, 'Peak Turns Per Epoch', turnsComparisonData,
                    playerData.averageData.turnsPeakPerEpoch, true, null, 1, 'Turns (N/min)', null,
                    playerData.averageData.turnsPeakPerEpoch.map(v => v.toFixed(1) + 'N/min'));
            }

        },
        resetPlots() {
            this.playerName = this.selectedPlayer.name;
            this.hasPeakDemands = this.data.playerData.averageData.distancePeakPerEpoch.length > 0;

            Object.values(this.charts).forEach(chart => {
                if (chart) {
                    chart.destroy();
                }
            });
            this.charts = { distance: null, hsr: null, sprint: null, turns: null };
        }
    },
    async mounted() {
        this.resetPlots();
        await this.getPeakDemands();
    },
    watch: {
        data() {
            this.resetPlots();
            this.getPeakDemands();
        },
    },
    computed: {
        selectedPlayer() {
            return store.state.selectedPlayer;
        }
    },
};
</script>

<style>

</style>