<template>
    <EditModal :cancel="cancelEditSession" :id="id" :title="'Edit Session'"
        :cancelVariant="'secondary standard-btn'" :ok=updateSession>
        <div class="alert alert-danger" v-if="this.warningText">
            {{ this.warningText }}
        </div>
        <fieldset>
            <div class="form-group">
                <label for="edit-title">Title</label>
                <input type="text" id="edit-title" name="edit-title" class="form-control"
                    placeholder="Enter session title" v-model="title" />
            </div>
            <div class="form-group">
                <label for="edit-type">Session type</label>
                <b-form-select id="edit-type" v-model="type" :options="sessionTypeOptions"></b-form-select>
            </div>
        </fieldset>
    </EditModal>
</template>

<script>
import { errorHandler } from "@/components/ErrorHandler";
import configStore from "@/store/config";

export default {
    props: {
        id: String,
        customerId: String,
        session: Object,
        refresh: Function,
    },
    data() {
        return {
            sessionId: null,
            title: "",
            type: null,
            editModal: false,
            sessionTypeOptions: [],
            warningText: "",
        };
    },
    watch: {
        session: {
            immediate: true,
            handler(newSession) {
                if (newSession) {
                    this.updateEditModalFields(newSession);
                }
            }
        }
    },
    methods: {
        async updateEditModalFields(session) {
            this.sessionId = session.sessionId;
            this.title = session.title;
            this.type = session.sessionType.id;
            this.editModal = true;
        },
        async getSessionTypes() {
            this.sessionTypeOptions = configStore.state.sessionTypes.map(st => ({
                value: st.id,
                text: st.name,
                colour: st.colour
            }));
        },
        async updateSession() {
            this.warningText = "";
            const json = {
                sessionId: this.session.sessionId, customerId: this.customerId,
                title: this.title, sessionTypeId: this.type
            };
            const response = await this.$root.webApiPost(`/session`, json);
            if (response.status === 200) {
                this.editModal = false;
                this.sessionId = 0;
                await this.refresh();
            } else {
                errorHandler.error(response, this);
            }
        },
        cancelEditSession() {
            this.sessionId = 0;
            this.editModal = false;
        },
    },
    async mounted() {
        await this.getSessionTypes();
    }
};
</script>
